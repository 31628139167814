import styled from "styled-components";

export const PiWrapper = styled.div`
  font: normal normal 600 16px/23px Montserrat;
  & .pi-title {
    color: #c10e21;
    text-decoration: underline;
    background-color: inherit;
    border: none;
    font: normal normal 600 16px/23px Montserrat;
  }

  & .budget-value {
    color: #c10e21;
  }

  & .editnav {
    flex-direction: column;
    justify-content: center;
    align-content: space-between;
    @media (min-width: 550px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
  & .createPi {
    width: 100%;
    justify-content: center;
    @media (min-width: 550px) {
      width: auto;
    }
    & .createPiButton {
      width: 80%;
      display: flex;
      justify-content: center;
      margin-top: 10px;
      @media (min-width: 550px) {
        width: auto;
        margin-top: 0px;
      }
    }
  }
  & .editNav-hidden {
    display: none !important;
  }
  & #progressBar {
    width: 80%;
    background-color: #bbbbbb;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const AnotherWraper = styled.div``;
