/* eslint-disable no-debugger, no-console */
// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from "react";
import Loader from "components/Loader";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import ScrollToTop from "components/scrollToTop";
import Toast from "components/Toast";
import AsideBar from "components/AsideBar";
import { useDispatch } from "react-redux";
import Header from "components/Header";
import DashboardGraph from "components/DashboardComponents/DashboardGraph/index";
import Tables from "components/Table";
import ProjectList from "services/api/ProjectListApi";
import { DashboardWrapper } from "./styled";
import Footer from "../../components/AdminFooter/index";
import DashBoardCard from "../../components/DashboardComponents/DashBoardCards/index";

const Dashboard = () => {
  const [DataArr, setDataArr] = useState<any>([]);
  const dispatch = useDispatch();
  const getProjects = async () => {
    await ProjectList(1)
      .then((data: any) => {
        setDataArr((oldArray: any) => [...oldArray, data.data.projects]);
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      })
      .catch((error) => {
        console.log(error.response.data.error);
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      });
  };
  useEffect(() => {
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    getProjects();
  }, []);

  return (
    <DashboardWrapper className="container-fluid d-flex flex-column w-100 p-0">
      <main className="d-flex" id="maincontent">
        <div className="content-container">
          <AsideBar />
          <div className="left-container flex-grow-1">
            <Header />
            <ScrollToTop />
            <DashBoardCard />
            {/* REQUIRED FOR FUTURE CHANGES */}
            {/* <DashboardGraph /> */}
            <div className="T-Title d-flex justify-content-between w-100">
              <div className="T-Titles">
                <strong>Recently Updated Projects</strong>
              </div>
              {/* REQUIRED FOR FUTURE CHANGES */}
              {/* <div className="S-Stauses d-flex justify-content-between">
                <div className="S-Status pt-1 align-items-center">
                  <span className="green-arrow mr-1">
                    <img
                      src="/images/Group 6015.png"
                      alt="Green Arrow"
                      style={{ width: "10px", height: "10px" }}
                    />
                  </span>
                  No Status Change
                </div>
                <div className="S-Status pt-1 Status-Updated align-items-center pb-1">
                  <span className="red-arrow">
                    <img
                      src="/images/Group 6034@2x.png"
                      alt="Green Arrow"
                      style={{ width: "10px", height: "10px" }}
                    />
                  </span>
                  |
                  <span className="red-arrow">
                    <img
                      src="/images/Group 6034@2x.png"
                      alt="Green Arrow"
                      style={{ width: "10px", height: "10px" }}
                    />
                  </span>
                  <span>Status Updated</span>
                </div>
              </div> */}
            </div>
            <Tables DataArr={DataArr} />
          </div>
        </div>
      </main>
      <Footer />
      <Toast />
      <Loader />
    </DashboardWrapper>
  );
};

export default Dashboard;
