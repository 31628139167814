/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { showToast } from "components/Toast/toast.slice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import GetPiById from "services/api/GetPiByIdApi";
import UpdatePiApi from "services/api/UpdatePiApi";
import CONSTANTS from "../../../common/constants";
import { EditPiWrapper } from "./styled";

function EditPi(props: any) {
  const { setActive } = props;
  const [confirmEnd, setConfirmEnd] = useState(false);
  const [confirmStart, setConfirmStart] = useState(false);
  const tablePiState = () => setActive("PiTable");
  const [disabled, setDisabled] = useState(true);
  const [disableStart, setDisableStart] = useState(false);
  const dispatch = useDispatch();
  const id = localStorage.getItem("piId");
  const projectId = localStorage.getItem("projectId");
  let flag = true;
  const status = localStorage.getItem("piStatus");
  const today = new Date(Date.now());
  if (status === CONSTANTS.ACTIVE) {
    flag = true;
  } else flag = false;

  const [Data, setData] = useState({
    name: "",
    startDate: "",
    endDate: "",
    schedule: 0,
    budget: "",
    scope: "",
    status: false,
    projectId: Number(projectId),
  });

  const getPi = async () => {
    await GetPiById(id)
      .then((data: any) => {
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
        /* if (today > new Date(data.data.Pi.endAt)) {
          setDisableStart(true);
        } */
        setData({
          ...Data,
          name: data.data.Pi.name,
          scope: data.data.Pi.piScope,
          status: data.data.Pi.piStatus,
          startDate: data.data.Pi.startAt,
          endDate: data.data.Pi.endAt,
          budget: data.data.Pi.piBudget,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    getPi();
  }, []);
  const onSave = async (e: any) => {
    e.preventDefault();
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    await UpdatePiApi(Data, id)
      .then((data: any) => {
        tablePiState();
        const toast = {
          visibility: true,
          message: "Pi Updated Successfully",
          title: "Success",
          type: "success",
        };
        dispatch(showToast(toast));
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      })
      .catch((error: any) => {
        console.log(error);
        if (!(error.response.data.error.details === undefined)) {
          console.log("empty date");
        }
        console.log(
          "signinRedirect error: ",
          error.response.data.error.details[0].message
        );
        const toast = {
          visibility: true,
          message: error.response.data.error.details[0].message,
          title: "Failure",
          type: "danger",
        };
        dispatch(showToast(toast));
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      });
  };

  function ConfirmEndFunc() {
    return (
      <div className={confirmEnd ? "overlay" : "overlay-hidden"} id="name">
        <div className={confirmEnd ? "background-overlay" : "overlay-hidden"}>
          <label
            htmlFor="ButtonEnd"
            className={
              confirmEnd
                ? "background-overlay flex-direction-row"
                : "overlay-hidden"
            }
          >
            Are you sure you want to end Pi Name: {Data.name}?
          </label>
          <div className="d-flex justify-content-around w-100">
            <button
              type="button"
              className={
                confirmEnd
                  ? "btn btn-round btn-primary piBtn"
                  : "overlay-hidden"
              }
              id="ButtonEnd"
              onClick={(event) => {
                event.preventDefault();
                setConfirmEnd(false);
                setData({ ...Data, status: false });
                onSave(event);
                localStorage.setItem("piStatus", "Inactive");
              }}
            >
              Yes
            </button>
            <button
              type="button"
              className={
                confirmEnd
                  ? "btn btn-round btn-secondary piBtn"
                  : "overlay-hidden"
              }
              id="ButtonEnd"
              onClick={(event) => {
                event.preventDefault();
                setConfirmEnd(false);
                setData({ ...Data, status: true });
              }}
            >
              No
            </button>
          </div>
        </div>
      </div>
    );
  }

  function ConfirmStartFunc() {
    return (
      <div className={confirmStart ? "overlay" : "overlay-hidden"} id="name">
        <div className={confirmStart ? "background-overlay" : "overlay-hidden"}>
          <label
            htmlFor="ButtonStart"
            className={
              confirmStart
                ? "background-overlay flex-direction-row"
                : "overlay-hidden"
            }
          >
            Are you sure you want to start Pi Name: {Data.name}?
          </label>
          <div className="d-flex justify-content-around w-100">
            <button
              type="button"
              className={
                confirmStart
                  ? "btn btn-round btn-primary piBtn"
                  : "overlay-hidden"
              }
              id="ButtonStart"
              onClick={(event) => {
                event.preventDefault();
                setConfirmStart(false);
                setData({ ...Data, status: true });
                onSave(event);
                localStorage.setItem("piStatus", "Active");
              }}
            >
              Yes
            </button>
            <button
              type="button"
              className={
                confirmStart
                  ? "btn btn-round btn-secondary piBtn"
                  : "overlay-hidden"
              }
              id="ButtonStart"
              onClick={(event) => {
                event.preventDefault();
                setConfirmStart(false);
                setData({ ...Data, status: false });
              }}
            >
              No
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <EditPiWrapper id="maincontent">
      <ConfirmEndFunc />
      <ConfirmStartFunc />
      <div className="top-div d-flex justify-content-between">
        <div className="filter-bar">
          <div className="piStatus">
            <p>Pi Status</p>
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-end">
          <button
            type="button"
            className="btn editicon-btn"
            disabled={props.onHoldProp || disableStart}
            onClick={() => {
              setDisabled(!disabled);
            }}
          >
            <i className="aha-icon-edit edit-icon" />
          </button>
          <button
            type="button"
            className="btn btn-round btn-primary piBtn"
            disabled={
              (!Data.status && flag) || props.onHoldProp
                ? true
                : false || disableStart
            }
            onClick={(e) => {
              e.preventDefault();
              if (Data.status === false) {
                setData({
                  ...Data,
                  status: true,
                });
                setConfirmStart(true);
              } else {
                setData({
                  ...Data,
                  status: false,
                });
                setConfirmEnd(true);
              }
            }}
          >
            {Data.status ? "End" : "Start"} Pi
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-between pb-4 align-items-center form-div">
        <form className="d-flex justify-content-around flex-column w-100 pt-3">
          <div className="form-group row required">
            <label htmlFor="piName" className="col-sm-2 col-form-label">
              Pi Name
            </label>
            <div className="col-md-7">
              <input
                required
                className="form-control input-form"
                type="text"
                id="piName"
                disabled={disabled}
                defaultValue={Data.name}
                onChange={(event) =>
                  setData({
                    ...Data,
                    name: event.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="form-group row required" hidden={!disabled}>
            <label htmlFor="startDate" className="col-sm-2 col-form-label">
              Start Date
            </label>
            <div className="col-md-7">
              <input
                type="text"
                required
                className="form-control"
                id="startDate"
                placeholder={Data.startDate
                  .substring(0, 10)
                  .split("-")
                  .join("-")}
                disabled={disabled}
                onChange={(event) =>
                  setData({
                    ...Data,
                    startDate: new Date(event.target.value).toISOString(),
                  })
                }
              />
            </div>
          </div>
          <div className="form-group row required" hidden={!disabled}>
            <label htmlFor="endDate" className="col-sm-2 col-form-label">
              End Date
            </label>
            <div className="col-md-7">
              <input
                required
                className="form-control"
                type="text"
                id="endDate"
                placeholder={Data.endDate.substring(0, 10).split("-").join("-")}
                disabled={disabled}
                onChange={(event) =>
                  setData({
                    ...Data,
                    endDate: new Date(event.target.value).toISOString(),
                  })
                }
              />
            </div>
          </div>
          <div className="form-group row required" hidden={disabled}>
            <label htmlFor="schedule" className="col-sm-2 col-form-label">
              Schedule
            </label>
            <div className="col-md-7">
              <div className="d-flex justify-content-between dateTags">
                <p>{CONSTANTS.SLIDERVAL1}</p>
                <p>{CONSTANTS.SLIDERVAL2}</p>
              </div>
              <div
                className="d-flex justify-content-between dateTags"
                aria-hidden="true"
              >
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
              </div>
              <input
                required
                type="range"
                id="startDate"
                defaultValue="0"
                min="1"
                max="17"
                onChange={(event) => {
                  setData({
                    ...Data,
                    schedule: Number(event.target.value),
                  });
                }}
              />
              <div className="sliderValue">
                <span>Duration: </span>
                <span className="duration">{Data.schedule + " Weeks"}</span>
              </div>
            </div>
          </div>{" "}
          <div className="form-group row required">
            <label htmlFor="scope" className="col-sm-2 col-form-label">
              Scope
            </label>
            <div className="col-md-7">
              <input
                required
                className="form-control input-form"
                type="text"
                id="scope"
                defaultValue={Data.scope}
                disabled={disabled}
                onChange={(event) =>
                  setData({
                    ...Data,
                    scope: event.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="form-group row required">
            <label htmlFor="budget" className="col-sm-2 col-form-label">
              Budget
            </label>
            <div className="col-md-7">
              <input
                required
                className="form-control input-form"
                type="text"
                id="budget"
                defaultValue={Data.budget}
                disabled={disabled}
                onChange={(event) =>
                  setData({
                    ...Data,
                    budget: event.target.value.toString(),
                  })
                }
              />
            </div>
          </div>
          <hr className="bottomHr" />
          <div className="d-flex justify-content-end">
            <button
              type="button"
              hidden={disabled}
              className="btn btn-secondary btn-round"
              onClick={(e) => {
                e.preventDefault();
                setDisabled(!disabled);
              }}
            >
              Go Back
            </button>
            <button
              type="submit"
              hidden={disabled}
              className="btn btn-primary btn-round"
              onClick={(e) => {
                e.preventDefault();
                onSave(e);
              }}
            >
              Submit
            </button>
          </div>
          <div className="d-flex justify-content-start">
            <button
              type="button"
              hidden={!disabled}
              className="btn btn-secondary btn-round bck-btn"
              onClick={(e) => {
                e.preventDefault();
                tablePiState();
              }}
            >
              &#60; Go Back
            </button>
          </div>
        </form>
      </div>
    </EditPiWrapper>
  );
}

export default EditPi;
