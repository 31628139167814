import styled from "styled-components";

export const EditPiWrapper = styled.div`
  & .overlay-hidden {
    display: none;
  }
  & .editicon-btn {
    background: none !important;
    border: none !important;
    & .edit-icon {
      color: #c10e21;
      font-size: 30px;
    }
    & .edit-icon:hover {
      shadow: 0 0 0 #c10e21;
    }
  }

  & input {
    border: 1px solid #bcc3ca;
  }

  & input:disabled {
    border: none !important;
    background-color: white;
    cursor: default;
  }

  & .overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    height: 110vh;
    background: rgba(0, 0, 0, 0.56);
    overflow: auto;
    z-index: 3;
    justify-content: center;
    width: 100vw;
    align-items: center;
    margin-left: -280px;
    margin-top: -266px;
  }
  & .background-overlay {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
  }
  & .overlay-hidden {
    display: none;
  }
  & .top-div {
    background: #edf2f6 0% 0% no-repeat padding-box;
    border: 1px solid #dcdddf;
    justify-content: space-between;
    & button {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 30px;
    }
    & .filter-bar {
      display: flex;
      justify-content: start;
      background: #edf2f6;
      height: 100px;
      width: 40%;
      & button {
        margin: auto;
      }
      & .piStatus {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 10px;
        & p {
          font: normal normal 500 18px/22px Montserrat;
          margin: auto;
        }
      }
    }
    & .editicon-btn {
      background: none !important;
      border: none !important;
      & .edit-icon {
        color: #c10e21;
        font-size: 30px;
      }
    }
    & .edit-icon:hover {
      shadow: 0 0 0 #c10e21;
    }
  }
  & .form-div {
    margin-top: 20px;
    & form {
      width: 50%;
      & .row {
        margin-left: 0px;
        margin-right: 0px;
      }
      & .dateTags {
        margin-bottom: -20px;
        & p {
          font-size: 12px;
          color: #222328;
        }
        @media only screen and (min-width: 992px) {
          width: 400px;
        }
      }
      & .input-form {
        border: 1px solid #bcc3ca;
        @media only screen and (min-width: 992px) {
          width: 400px;
        }
      }
      & input[type="range"] {
        width: 100%;
        -webkit-appearance: none;
        z-index: 1;
        overflow: hidden;
        border-radius: 5px;
        height: 10px;
        outline: none;
        background: #ececec 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029;
        @media only screen and (min-width: 992px) {
          width: 400px;
          height: 10px;
        }
      }
      & input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        position: relative;
        width: 14px;
        height: 14px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 4px #00000029;
        border: 0.5px solid #00000029;
        border-radius: 5px;
        opacity: 1;
        box-shadow: -400px 0 0 400px #c10e21;
        cursor: pointer;
        z-index: 2;
      }

      & input[type="range"]::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        color: #ececec;
        box-shadow: inset 0px 3px 6px #00000029;
      }
      & .sliderValue {
        width: 100%;
        margin-bottom: 5px;
      }
      & .sliderValue span {
        font-size: 13px;
        padding-top: 2px;
        font-weight: 500;
        z-index: 2;
      }
      & .duration {
        color: #c10e21;
      }
      & input:disabled {
        border: none !important;
        background-color: white;
        cursor: default;
      }
      & button {
        margin-right: 20px;
      }
      & .bck-btn {
        border: none;
        font-weight: 600;
      }
    }
  }

  & .currency-button {
    border-top: none !important;
    border-left: none !important;
    border-bottom: none !important;
    border-right: 1px solid #bcc3ca !important;
    border-radius: 0px !important;
  }
  & .currency-hidden {
    display: none;
  }
  & .budget-border {
    border: 1px solid #bcc3ca !important;
    width: auto !important;
    @media only screen and (min-width: 992px) {
      width: 400px !important;
    }
  }
`;

export const AnotherWraper = styled.div``;
