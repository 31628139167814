import React from "react";
import AdminHeaderWrapper from "./styled";

const Global: any = global;
export const AdminHeader = () => {
  const signout = () => {
    Global.userManager.signoutRedirect();
  };

  return (
    <AdminHeaderWrapper className="aui-main-header aui-pri-header">
      <nav className="navbar navbar-expand-lg justify-content-between aui-header-content aui-pri-header-t">
        <a
          href="/"
          className="aha-certification-logo"
          aria-label="Project Health Report Tool logo"
        >
          Project Health Report Tool logo
        </a>
      </nav>
    </AdminHeaderWrapper>
  );
};
export default AdminHeader;
