import styled from "styled-components";

const AddProjectWrapper = styled.div`
  min-height: 100vh;

  #maincontent {
    & .row {
      margin-right: 0px;
    }
    @media (min-width: 992px) {
      height: 100%;
      flex-grow: 1;
    }
    & .content-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      @media (min-width: 992px) {
        flex-direction: row;
      }
      & .left-container {
        padding: 10px;
        @media (min-width: 550px) {
          padding: 15px;
        }
        @media (min-width: 991px) {
          padding: 19px 10px 19px 21px;
        }
        & main {
          @media (min-width: 360px) {
            display: flex;
            flex-direction: column;
            & .titleDiv {
              margin-bottom: 20px;
              padding-bottom: 20px;
              border-bottom: 0.6000000238418579px solid #bcc3ca;
            }
            & .firstP {
              font-weight: 600;
              border-bottom: 3px solid #c10e21;
              width: fit-content;
              padding-bottom: 5px;
            }
            & input {
              border: 0.6000000238418579px solid #bcc3ca;
            }
            & textarea {
              border: 0.6000000238418579px solid #bcc3ca;
            }

            & .bottomHr {
              margin-top: 40px;
            }

            & .formButtonDiv {
              display: flex;
              justify-content: flex-end;
              margin-top: 40px;
              @media (min-width: 530px) {
                width: 100%;
              }
              & button {
                width: 100%;
                height: 40px;
                border-radius: 20px;
                margin-right: 20px;
                border: 1px solid #c10e21;
                @media (min-width: 530px) {
                  width: 128px;
                }
              }

              & .cancelBtn {
                background: white;
                color: #c10e21;
              }

              & .saveBtn {
                background: #c10e21;
                color: white;
                border: none;
              }
            }
            & .input-field {
              margin-bottom: 20px;
              width: 100%;
              max-width: 100%;
              margin: 0px;
              display: flex;
              @media (min-width: 360px) {
                width: 400px;
              }
            }
            & .aui-search-dropdown {
              max-width: 100%;
            }
            & .currency-button {
              border-top: none !important;
              border-left: none !important;
              border-bottom: none !important;
              border-right: 1px solid #bcc3ca !important;
              border-radius: 0px !important;
            }
            & .budget-border {
              border: 1px solid #bcc3ca !important;
            }
          }
          & .titleDiv {
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 0.6000000238418579px solid #bcc3ca;
          }
          & .firstP {
            font-weight: 600;
            border-bottom: 3px solid #c10e21;
            width: fit-content;
            padding-bottom: 5px;
            margin-top: 15px;
          }
          & input {
            border: 0.6000000238418579px solid #bcc3ca;
          }
          & textarea {
            border: 0.6000000238418579px solid #bcc3ca;
          }
          & .formButtonDiv {
            width: 100%;
            flex-direction: column-reverse;
            @media (min-width: 520px) {
              display: flex;
              flex-direction: row;
              margin: 10px;
            }
          }
        }
      }
    }
  }
`;

export default AddProjectWrapper;
