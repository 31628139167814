/* eslint-disable no-console */
import Loader from "components/Loader";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { showToast } from "components/Toast/toast.slice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CreateReportApi from "services/api/CreateReportApi";
import PiActive from "services/api/GetActivePi";
import CONSTANTS from "common/constants";
import { AddReportFormWrapper, AddReportInactiveWrapper } from "./styled";

export const AddReportForm = (props: any) => {
  const { setActiveReport } = props;
  const ReportTableState = () => {
    setActiveReport("ReportTable");
  };
  const projectID = localStorage.getItem("projectId");
  const [selected, setSelected] = useState<any>(false);
  const [toggle, setToggle] = useState({
    scope: "green",
    quality: "green",
    budget: "green",
    schedule: "green",
    health: "green",
  });
  const [DataArr, setDataArr] = useState<any>([]);
  const previousReportId = localStorage.getItem("reportId");
  const [reportForm, setReportForm] = useState({
    budget: CONSTANTS.GOODCHANGE,
    budget_comment: "",
    schedule: CONSTANTS.GOODCHANGE,
    schedule_comment: "",
    overall_health: CONSTANTS.GOODCHANGE,
    overall_health_comment: "",
    overall_health_percentage: 0,
    scope: CONSTANTS.GOODCHANGE,
    scope_comment: "",
    pi_id: null,
    projectId: Number(projectID),
    start_date: "",
    end_date: "",
    quality: CONSTANTS.GOODCHANGE,
    quality_comment: "",
    week: 0,
    reportId: Number(previousReportId),
  });
  const dispatch = useDispatch();

  const onSave = async (e: any) => {
    e.preventDefault();
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    await CreateReportApi(reportForm)
      .then((data: any) => {
        ReportTableState();
        const toast = {
          visibility: true,
          message: "Report Added Successfully",
          title: "Success",
          type: "success",
        };
        dispatch(showToast(toast));
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      })
      .catch((error: any) => {
        console.log(error);
        const invalidPiToast = {
          visibility: true,
          message: "Select PI before submitting",
          title: "Failure",
          type: "danger",
        };
        if (error.response === undefined) {
          console.log("empty date");
          dispatch(showToast(invalidPiToast));
          dispatch(setPageLoadingStatus({ isPageLoading: false }));
          return;
        }
        const toast = {
          visibility: true,
          message: error.response.data.error.details[0].message,
          title: "Failure",
          type: "danger",
        };
        dispatch(showToast(toast));
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      });
  };

  const getActivePi = async () => {
    await PiActive(projectID)
      .then((data: any) => {
        setDataArr((oldArray: any) => [...oldArray, data.data.Pi]);
        setReportForm({
          ...reportForm,
          pi_id: data.data.Pi.id,
          start_date: data.data.Pi.startAt,
          end_date: data.data.Pi.endAt,
        });
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getActivePi();
  }, []);

  while (DataArr[0] === undefined) {
    return (
      <AddReportInactiveWrapper>
        <div className="inactive-report-form">
          <h4>No Active Pi</h4>
          <div className="button-hr">
            <hr />
          </div>
          <button
            type="button"
            className="btn btn-secondary btn-round"
            onClick={() => {
              ReportTableState();
            }}
          >
            <strong> &#60; </strong> Back
          </button>
        </div>
      </AddReportInactiveWrapper>
    );
  }

  return (
    <AddReportFormWrapper className="text">
      <div>
        <form className="form">
          <div className="d-flex piDropdown">
            <div className="input-group flex-nowrap">
              <div className="aui-label">
                <span className="input-group-text aui-buyfor-txt">
                  <p className="activeLabel">Active PI :</p>
                  <p className="piActive"> {DataArr[0].name}</p>
                </span>
              </div>
            </div>
          </div>
          <div className="form-entry scopes">
            <div className=" d-flex  form-group row required  col-12 justify-content-between pl-0 pr-0 mr-0 ml-0 forminput">
              <label
                htmlFor="scope"
                className="col-2 col-form-label scope pl-0 pr-0 pt-0"
              >
                Scope{" "}
              </label>
              <textarea
                className="form-control col-md-7 textarea"
                id="scope"
                placeholder="Add Comment"
                disabled={selected}
                onChange={(event) => {
                  setReportForm({
                    ...reportForm,
                    scope_comment: event.target.value,
                  });
                }}
              />

              <div className="toggle-buttons d-flex col-2 align-items-top pl-0 pr-0">
                <button
                  className={
                    toggle.scope === "green"
                      ? "toggle-buttons-green col-6 text-center align-middle text-white pt-0 pb-0 pr-0 pl-0 green"
                      : "toggle-buttons-green col-6 text-center align-middle pt-0 pb-0 pr-0 pl-0 "
                  }
                  aria-label="green toggle"
                  type="button"
                  id="scope"
                  onClick={() => {
                    setToggle({
                      ...toggle,
                      scope: "green",
                    });
                    setReportForm({
                      ...reportForm,
                      scope: "goodchange",
                    });
                  }}
                >
                  <p>Green</p>
                </button>
                <button
                  className={
                    toggle.scope === "red"
                      ? "toggle-buttons-red col-6 text-center align-middle text-white pt-0 pb-0 pr-0 pl-0 red"
                      : "toggle-buttons-red col-6 text-center align-middle pt-0 pb-0 pr-0 pl-0 "
                  }
                  aria-label="red toggle"
                  type="button"
                  id="scope"
                  onClick={() => {
                    setToggle({
                      ...toggle,
                      scope: "red",
                    });
                    setReportForm({
                      ...reportForm,
                      scope: "badchange",
                    });
                  }}
                >
                  <p>Red</p>
                </button>
              </div>
            </div>
          </div>
          <div className="form-entry">
            <div className=" d-flex  form-group row required  col-sm-12 justify-content-between pl-0 pr-0 mr-0 ml-0 forminput">
              <label
                htmlFor="quality"
                className="col-2 col-form-label quality pl-0 pr-0 pt-0"
              >
                Quality{" "}
              </label>
              <textarea
                className="form-control col-md-7 textarea"
                id="scope"
                placeholder="Add Comment"
                disabled={selected}
                onChange={(event) => {
                  setReportForm({
                    ...reportForm,
                    quality_comment: event.target.value,
                  });
                }}
              />

              <div className="toggle-buttons d-flex col-2 align-items-top pl-0 pr-0">
                <button
                  className={
                    toggle.quality === "green"
                      ? "toggle-buttons-green col-6 text-center align-middle text-white pt-0 pb-0 pr-0 pl-0 green"
                      : "toggle-buttons-green col-6 text-center align-middle pt-0 pb-0 pr-0 pl-0 "
                  }
                  aria-label="green toggle"
                  type="button"
                  id="quality"
                  onClick={() => {
                    setToggle({
                      ...toggle,
                      quality: "green",
                    });
                    setReportForm({
                      ...reportForm,
                      quality: "goodchange",
                    });
                  }}
                >
                  <p>Green</p>
                </button>
                <button
                  className={
                    toggle.quality === "red"
                      ? "toggle-buttons-red col-6 text-center align-middle text-white pt-0 pb-0 pr-0 pl-0 red"
                      : "toggle-buttons-red col-6 text-center align-middle pt-0 pb-0 pr-0 pl-0 "
                  }
                  aria-label="red toggle"
                  type="button"
                  id="quality"
                  onClick={() => {
                    setToggle({
                      ...toggle,
                      quality: "red",
                    });
                    setReportForm({
                      ...reportForm,
                      quality: "badchange",
                    });
                  }}
                >
                  <p>Red</p>
                </button>
              </div>
            </div>
          </div>
          <div className="form-entry scopes">
            <div className=" d-flex  form-group row required  col-12 justify-content-between pl-0 pr-0 mr-0 ml-0 forminput">
              <label
                htmlFor="budget"
                className="col-2 col-form-label budget pl-0 pr-0 pt-0"
              >
                Budget{" "}
              </label>
              <textarea
                className="form-control col-md-7 textarea"
                id="scope"
                placeholder="Add Comment"
                disabled={selected}
                onChange={(event) => {
                  setReportForm({
                    ...reportForm,
                    budget_comment: event.target.value,
                  });
                }}
              />
              <div className="toggle-buttons d-flex col-2 align-items-top pl-0 pr-0">
                <button
                  className={
                    toggle.budget === "green"
                      ? "toggle-buttons-green col-6 text-center align-middle text-white pt-0 pb-0 pr-0 pl-0 green"
                      : "toggle-buttons-green col-6 text-center align-middle pt-0 pb-0 pr-0 pl-0 "
                  }
                  aria-label="green toggle"
                  type="button"
                  id="budget"
                  onClick={() => {
                    setToggle({
                      ...toggle,
                      budget: "green",
                    });
                    setReportForm({
                      ...reportForm,
                      budget: "goodchange",
                    });
                  }}
                >
                  <p>Green</p>
                </button>
                <button
                  className={
                    toggle.budget === "red"
                      ? "toggle-buttons-red col-6 text-center align-middle text-white pt-0 pb-0 pr-0 pl-0 red"
                      : "toggle-buttons-red col-6 text-center align-middle pt-0 pb-0 pr-0 pl-0 "
                  }
                  aria-label="red toggle"
                  type="button"
                  id="budget"
                  onClick={() => {
                    setToggle({
                      ...toggle,
                      budget: "red",
                    });
                    setReportForm({
                      ...reportForm,
                      budget: "badchange",
                    });
                  }}
                >
                  <p>Red</p>
                </button>
              </div>
            </div>
          </div>
          <div className="form-entry">
            <div className=" d-flex  form-group row required  col-sm-12 justify-content-between pl-0 pr-0 mr-0 ml-0 forminput">
              <label
                htmlFor="Schedule"
                className="col-2 col-form-label quality pl-0 pr-0 pt-0"
              >
                Schedule{" "}
              </label>
              <textarea
                className="form-control col-md-7 textarea"
                id="schedule"
                placeholder="Add Comment"
                disabled={selected}
                onChange={(event) => {
                  setReportForm({
                    ...reportForm,
                    schedule_comment: event.target.value,
                  });
                }}
              />

              <div className="toggle-buttons d-flex col-2 align-items-top pl-0 pr-0">
                <button
                  className={
                    toggle.schedule === "green"
                      ? "toggle-buttons-green col-6 text-center align-middle text-white pt-0 pb-0 pr-0 pl-0 green"
                      : "toggle-buttons-green col-6 text-center align-middle pt-0 pb-0 pr-0 pl-0 "
                  }
                  aria-label="green toggle"
                  type="button"
                  id="schedule"
                  onClick={() => {
                    setToggle({
                      ...toggle,
                      schedule: "green",
                    });
                    setReportForm({
                      ...reportForm,
                      schedule: "goodchange",
                    });
                  }}
                >
                  <p>Green</p>
                </button>
                <button
                  className={
                    toggle.schedule === "red"
                      ? "toggle-buttons-red col-6 text-center align-middle text-white pt-0 pb-0 pr-0 pl-0 red"
                      : "toggle-buttons-red col-6 text-center align-middle pt-0 pb-0 pr-0 pl-0 "
                  }
                  aria-label="red toggle"
                  type="button"
                  id="schedule"
                  onClick={() => {
                    setToggle({
                      ...toggle,
                      schedule: "red",
                    });
                    setReportForm({
                      ...reportForm,
                      schedule: "badchange",
                    });
                  }}
                >
                  <p>Red</p>
                </button>
              </div>
            </div>
          </div>
          <div className="form-entry scopes">
            <div className=" d-flex  form-group row required  col-sm-12 justify-content-between pl-0 pr-0 mr-0 ml-0 forminput pb-0 mb-0">
              <label
                htmlFor="overall-health"
                className="col-2 col-form-label overall-health pl-0 pr-0 pt-0 pb-0"
              >
                Overall Health (%){" "}
              </label>

              <input
                type="number"
                className="form-control col-md-7"
                id="overall-health-percentage"
                aria-label="enter overall-health"
                disabled={selected}
                onChange={(event) => {
                  setReportForm({
                    ...reportForm,
                    overall_health_percentage: Number(event.target.value),
                  });
                }}
              />

              <div className="toggle-buttons d-flex col-2 align-items-top pl-0 pr-0">
                <button
                  className={
                    toggle.health === "green"
                      ? "toggle-buttons-green col-6 text-center align-middle text-white pt-0 pb-0 pr-0 pl-0 green"
                      : "toggle-buttons-green col-6 text-center align-middle pt-0 pb-0 pr-0 pl-0 "
                  }
                  aria-label="green toggle"
                  type="button"
                  id="overall-health"
                  onClick={() => {
                    setToggle({
                      ...toggle,
                      health: "green",
                    });
                    setReportForm({
                      ...reportForm,
                      overall_health: "goodchange",
                    });
                  }}
                >
                  <p>Green</p>
                </button>
                <button
                  className={
                    toggle.health === "red"
                      ? "toggle-buttons-red col-6 text-center align-middle text-white pt-0 pb-0 pr-0 pl-0 red"
                      : "toggle-buttons-red col-6 text-center align-middle pt-0 pb-0 pr-0 pl-0 "
                  }
                  aria-label="red toggle"
                  type="button"
                  id="overall-health"
                  onClick={() => {
                    setToggle({
                      ...toggle,
                      health: "red",
                    });
                    setReportForm({
                      ...reportForm,
                      overall_health: "badchange",
                    });
                  }}
                >
                  <p>Red</p>
                </button>
              </div>
            </div>
            <div className="form-group row d-flex justify-content-center col-sm-12 pl-0 pr-0 mb-0 ml-0 mr-0 overall-health">
              <textarea
                className="form-control col-md-7 textarea"
                id="overall-health"
                placeholder="Add Comment"
                disabled={selected}
                onChange={(event) => {
                  setReportForm({
                    ...reportForm,
                    overall_health_comment: event.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="lines" />
          <div className="buttons d-flex justify-content-end flex-wrap">
            <button
              className="btn cancel-button btn-round btn-secondary text-center p-0"
              type="button"
              onClick={ReportTableState}
            >
              Cancel
            </button>
            <button
              className="btn submit-button btn-round btn-primary text-center p-0"
              type="button"
              onClick={(e) => {
                onSave(e);
              }}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </AddReportFormWrapper>
  );
};
export default AddReportForm;
