/* eslint-disable no-console */
import axios from "axios";
import CONFIG from "../../config";

const AddUserApi = async (userForm: any) => {
  const bearerToken = localStorage.getItem("user") as string;
  const tenantId = "1";
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(bearerToken)}`,
      "X-Tenant-Id": tenantId,
      "Content-Type": "application/json",
    },
  };

  const formData = {
    email: userForm.email,
    password: userForm.password,
    CreatedBy: "super_admin",
    UpdatedBy: "super_admin",
  };

  await axios
    .post(
      `${CONFIG[process.env.REACT_APP_MY_ENV].apiEndPoints.account}/v1/users`,
      JSON.stringify(formData),
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export default AddUserApi;
