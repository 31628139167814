/* eslint-disable no-console */
import axios from "axios";
import CONFIG from "../../config";
import GetReportById from "./GetReportById";

const UpdateReportsApi = async (reportForm: any, id: any) => {
  const bearerToken = localStorage.getItem("user") as string;
  const tenantId = "1";
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(bearerToken)}`,
      "X-Tenant-Id": tenantId,
      "Content-Type": "application/json",
    },
  };

  const previousReport = await GetReportById(reportForm.reportId);
  const prev = previousReport.data.Report;

  function setArrows(reportField: any, prevField: any) {
    switch (reportField) {
      case "goodchange":
        switch (prevField) {
          case "goodchange":
            return "nochangegood";
          case "badchange":
            return "goodchange";
          case "nochangegood":
            return "nochangegood";
          case "nochangebad":
            return "goodchange";
          default:
            break;
        }
        break;
      case "badchange":
        switch (prevField) {
          case "goodchange":
            return "badchange";
          case "badchange":
            return "nochangebad";
          case "nochangegood":
            return "badchange";
          case "nochangebad":
            return "nochangebad";
          default:
            break;
        }
        break;
      default:
        break;
    }
    return reportField;
  }

  const formData = {
    budget: reportForm.budget,
    budget_comment: reportForm.budget_comment,
    schedule: reportForm.schedule,
    schedule_comment: reportForm.schedule_comment,
    overall_health: reportForm.overall_health,
    overall_health_comment: reportForm.overall_health_comment,
    overall_health_percentage: reportForm.overall_health_percentage,
    scope: reportForm.scope,
    scope_comment: reportForm.scope_comment,
    quality: reportForm.quality,
    quality_comment: reportForm.quality_comment,
    week: prev.week,
  };

  await axios
    .post(
      `${
        CONFIG[process.env.REACT_APP_MY_ENV].apiEndPoints.phrt
      }/v1/reports/${id}`,
      JSON.stringify(formData),
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export default UpdateReportsApi;
