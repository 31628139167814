/* eslint-disable no-underscore-dangle */
/* eslint-disable no-debugger, no-console */
// eslint-disable-next-line no-use-before-define
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import Loader from "components/Loader";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReportsList from "services/api/ReportListApi";
import history from "common/history";
import Footer from "components/Footer/index";
import AsideBar from "components/AsideBar";
import Header from "components/Header";
import ScrollToTop from "components/scrollToTop";
import Pagination, { IPagination } from "components/Pagination";
import { ProjectReportWrapper } from "./styled";

const ReportListPage = () => {
  const [DataArr, setDataArr] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState<any>();
  const [paginate, setPagination] = useState<IPagination>({
    pageNumber,
    pageSize: 10,
    totalCount: 20,
    totalPages: 2,
    isFirst: true,
    isLast: false,
    setPageNumber,
    paginationSetLimit: 10,
  });

  const dispatch = useDispatch();

  const getReport = async () => {
    await ReportsList(pageNumber)
      .then((data: any) => {
        setDataArr((oldArray: any) => [...oldArray, data.data.reports]);
        setPagination((oldpaginate: IPagination) => ({
          ...oldpaginate,
          ...data._pagination,
        }));
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  };
  useEffect(() => {
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    setDataArr([]);
    setPagination(paginate);
    getReport();
  }, [pageNumber]);
  while (DataArr[0] === undefined) {
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    return <Loader />;
  }

  localStorage.setItem(
    "reportId",
    DataArr[0].slice(0).reverse(1)[0].id.toString()
  );
  const handleClick = (e: any) => {
    history.push(`/projectlist/projectmanagement`);
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    window.location.reload();
  };
  const handleClickReport = (reportId: any) => {
    history.push(`/projectlist/projectmanagement?reportId=${reportId}&ptab=1`);
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    window.location.reload();
  };

  return (
    <ProjectReportWrapper className="container-fluid d-flex flex-column w-100 p-0">
      <main className="d-flex" id="maincontent">
        <div className="d-flex content-container">
          <AsideBar />
          <div className="left-container flex-grow-1 px-3">
            <div className="d-flex justify-content-between mt-3 mx-3 heading-style mb-4">
              <h3 className="title">Reports</h3>
              <Header />
            </div>
            <div className="d-flex justify-content-between heading-bar p-3">
              <table className="aui-responsive-table">
                <thead>
                  <tr>
                    <th scope="col">Project Name</th>
                    <th scope="col">Report</th>
                    <th scope="col" className="text-lg-center">
                      Scope
                    </th>
                    <th scope="col" className="text-lg-center">
                      Quality
                    </th>
                    <th scope="col" className="text-lg-center">
                      Budget
                    </th>
                    <th scope="col" className="text-lg-center">
                      Schedule
                    </th>
                    <th scope="col" className="text-lg-center">
                      Project Health
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {DataArr[0].slice(0).map((report: any) => {
                    const startDate = new Date(report.start_date);
                    const longMonthStart = startDate.toLocaleString("en-us", {
                      month: "long",
                    });
                    const endDate = new Date(report.end_date);
                    const longMonthEnd = endDate.toLocaleString("en-us", {
                      month: "long",
                    });
                    const startAt = new Date(report.start_at);
                    const projectYearStart = startAt.toLocaleString("en-us", {
                      day: "numeric",
                      year: "numeric",
                      month: "long",
                    });
                    return (
                      <tr key={report.id}>
                        <td data-title="Project" className="project-content">
                          <div className="aui-td d-flex">
                            <div className="justify-content-between d-flex flex-column text-left">
                              <button
                                type="button"
                                className="project-title"
                                onClick={(e) => {
                                  localStorage.setItem(
                                    "projectId",
                                    report.project_id.toString()
                                  );
                                  localStorage.setItem(
                                    "projectName",
                                    report.project_name
                                  );
                                  handleClick(e);
                                }}
                              >
                                {report.project_name}
                              </button>
                              <div className="p-0 m-0 d-flex flex-column">
                                <span className="project-content pr-2">
                                  Started on: {projectYearStart}
                                </span>
                                <span>Status: {report.status}</span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td data-title="Report">
                          <div className="aui-td justify-content-between d-flex flex-column text-left">
                            <button
                              type="button"
                              onClick={(e) => {
                                localStorage.setItem("reportId", report.id);
                                localStorage.setItem(
                                  "projectName",
                                  report.project_name
                                );
                                localStorage.setItem(
                                  "projectId",
                                  report.project_id.toString()
                                );
                                handleClickReport(report.id);
                              }}
                              className="week-title"
                            >
                              Week {report.week}
                            </button>
                            <div className="duration-div">
                              {longMonthStart} {startDate.getDate()} -{" "}
                              {longMonthEnd} {endDate.getDate()}
                            </div>
                          </div>
                        </td>
                        <td data-title="Scope">
                          <div className="aui-td d-flex justify-content-center">
                            <img
                              className="arrow-image"
                              src={
                                report.scope === "nochangegood" ||
                                report.scope === "nochangebad"
                                  ? `${process.env.PUBLIC_URL}/images/ArrowNoChange.svg`
                                  : report.scope === "badchange"
                                  ? `${process.env.PUBLIC_URL}/images/ArrowBadChange.svg`
                                  : `${process.env.PUBLIC_URL}/images/ArrowGoodChange.svg`
                              }
                              alt="Directional Arrow No Change"
                            />
                          </div>
                          <div className="aui-td d-flex justify-content-center description">
                            <p className=" text-long">{report.scope_comment}</p>
                          </div>
                        </td>
                        <td data-title="Quality">
                          <div className="aui-td d-flex justify-content-center">
                            <img
                              className="arrow-image"
                              src={
                                report.quality === "nochangegood" ||
                                report.quality === "nochangebad"
                                  ? `${process.env.PUBLIC_URL}/images/ArrowNoChange.svg`
                                  : report.quality === "badchange"
                                  ? `${process.env.PUBLIC_URL}/images/ArrowBadChange.svg`
                                  : `${process.env.PUBLIC_URL}/images/ArrowGoodChange.svg`
                              }
                              alt="Directional Arrow Bad Change"
                            />
                          </div>
                          <div className="aui-td d-flex justify-content-center description">
                            <p className=" text-long">
                              {report.quality_comment}
                            </p>
                          </div>
                        </td>
                        <td data-title="Budget">
                          <div className="aui-td d-flex justify-content-center">
                            <img
                              className="arrow-image"
                              src={
                                report.budget === "nochangegood" ||
                                report.budget === "nochangebad"
                                  ? `${process.env.PUBLIC_URL}/images/ArrowNoChange.svg`
                                  : report.budget === "badchange"
                                  ? `${process.env.PUBLIC_URL}/images/ArrowBadChange.svg`
                                  : `${process.env.PUBLIC_URL}/images/ArrowGoodChange.svg`
                              }
                              alt="Directional Arrow Good Change"
                            />
                          </div>
                          <div className="aui-td d-flex justify-content-center description">
                            <p className=" text-long">
                              {report.budget_comment}
                            </p>
                          </div>
                        </td>
                        <td data-title="Schedule">
                          <div className="aui-td d-flex justify-content-center">
                            <img
                              className="arrow-image"
                              src={
                                report.schedule === "nochangegood" ||
                                report.schedule === "nochangebad"
                                  ? `${process.env.PUBLIC_URL}/images/ArrowNoChange.svg`
                                  : report.schedule === "badchange"
                                  ? `${process.env.PUBLIC_URL}/images/ArrowBadChange.svg`
                                  : `${process.env.PUBLIC_URL}/images/ArrowGoodChange.svg`
                              }
                              alt="Directional Arrow Good Change"
                            />
                          </div>
                          <div className="aui-td d-flex justify-content-center description">
                            <p className=" text-long">
                              {report.schedule_comment}
                            </p>
                          </div>
                        </td>
                        <td data-title="Project Health">
                          <div className="aui-td d-flex justify-content-center">
                            <img
                              className="arrow-image"
                              src={
                                report.overall_health === "nochangegood" ||
                                report.overall_health === "nochangebad"
                                  ? `${process.env.PUBLIC_URL}/images/ArrowNoChange.svg`
                                  : report.overall_health === "badchange"
                                  ? `${process.env.PUBLIC_URL}/images/ArrowBadChange.svg`
                                  : `${process.env.PUBLIC_URL}/images/ArrowGoodChange.svg`
                              }
                              alt="Directional Arrow Good Change"
                            />
                          </div>
                          <div className="aui-td d-flex justify-content-center description">
                            <p className=" text-long">
                              {report.overall_health_comment}
                            </p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-end">
              <Pagination
                pageNumber={paginate.pageNumber}
                pageSize={paginate.pageSize}
                setPageNumber={paginate.setPageNumber}
                paginationSetLimit={paginate.paginationSetLimit}
                totalCount={paginate.totalCount}
                totalPages={paginate.totalPages}
                isFirst={paginate.isFirst}
                isLast={paginate.isLast}
              />
            </div>
          </div>
        </div>
      </main>
      <ScrollToTop />
      <Footer />
    </ProjectReportWrapper>
  );
};

export default ReportListPage;
