/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { showToast } from "components/Toast/toast.slice";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import CreatePiApi from "services/api/CreatePiApi";
import CONSTANTS from "common/constants";
import { EditPiWrapper } from "./styled";

const CreatePi = (props: any) => {
  const { setActive } = props;
  const tablePiState = () => setActive("PiTable");
  const project = localStorage.getItem("projectId");
  const [piForm, setPiForm] = useState({
    name: "",
    startDate: "",
    endDate: "",
    status: false,
    budget: localStorage.getItem("budget"),
    projectId: Number(project),
    scope: "",
    progress: 100,
    schedule: 17,
  });

  const [projectBudget, setProjectBudget] = useState("");

  const dispatch = useDispatch();
  const onSave = async (e: any) => {
    e.preventDefault();
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    await CreatePiApi(piForm)
      .then((data: any) => {
        tablePiState();
        const toast = {
          visibility: true,
          message: "PI Added Successfully",
          title: "Success",
          type: "success",
        };
        dispatch(showToast(toast));
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      })
      .catch((error: any) => {
        console.log(error);
        if (!(error.response.data.error.details === undefined)) {
          console.log("empty date");
        }
        const toast = {
          visibility: true,
          message: error.response.data.error.details[0].message,
          title: "Failure",
          type: "danger",
        };
        dispatch(showToast(toast));
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      });
  };

  return (
    <EditPiWrapper id="maincontent container-fluid d-flex justify-content-between">
      <div className="d-flex justify-content-between pb-4 align-items-center">
        <form className="PiForm">
          <div className="form-group row required">
            <label htmlFor="piName" className="col-sm-2 col-form-label">
              Pi Name
            </label>
            <div className="col-md-7">
              <input
                data-testid="piName"
                required
                className="form-control"
                type="text"
                id="piName"
                onChange={(event) => {
                  setPiForm({
                    ...piForm,
                    name: event.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="form-group row required">
            <label htmlFor="startDate" className="col-sm-2 col-form-label">
              Schedule
            </label>
            <div className="col-md-7">
              <div className="d-flex justify-content-between dateTags">
                <p>{CONSTANTS.SLIDERVAL1}</p>
                <p>{CONSTANTS.SLIDERVAL2}</p>
              </div>
              <div
                className="d-flex justify-content-between dateTags"
                aria-hidden="true"
              >
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
                <p>I</p>
              </div>
              <input
                required
                data-testid="schedule"
                type="range"
                id="startDate"
                min="1"
                max="17"
                onChange={(event) => {
                  setPiForm({
                    ...piForm,
                    schedule: Number(event.target.value),
                  });
                }}
              />
              <div className="sliderValue">
                <span>Duration: </span>
                <span className="duration">{piForm.schedule + " Weeks"}</span>
              </div>
            </div>
          </div>
          <div className="form-group row required">
            <label htmlFor="scope" className="col-sm-2 col-form-label">
              Scope
            </label>
            <div className="col-md-7">
              <input
                required
                data-testid="scope"
                className="form-control"
                type="text"
                id="scope"
                onChange={(event) =>
                  setPiForm({
                    ...piForm,
                    scope: event.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="form-group row required">
            <label htmlFor="budget" className="col-sm-2 col-form-label">
              Budget
            </label>
            <div className="col-md-7">
              <input
                required
                className="form-control"
                data-testid="budget"
                type="text"
                id="budget"
                defaultValue={localStorage.getItem("budget")?.toString()}
                onChange={(event) =>
                  setPiForm({
                    ...piForm,
                    budget: event.target.value.toString(),
                  })
                }
              />
            </div>
          </div>
          <hr className="bottomHr" />
          <div className="d-flex justify-content-end btngroupdiv">
            <button
              type="button"
              className="btn btn-secondary btn-round text-nowrap border-0"
              onClick={(e) => {
                e.preventDefault();
                tablePiState();
              }}
            >
              &#60; Back
            </button>
            <button
              type="submit"
              data-testid="submit"
              className="btn btn-primary btn-round"
              onClick={(e) => {
                e.preventDefault();
                onSave(e);
              }}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </EditPiWrapper>
  );
};

export default CreatePi;
