import styled from "styled-components";

const CardsWrapper = styled.div`
  .cardboard_a {
    border-radius: 8px;
    border: 1px solid #6895e6;
    background-color: #eff4fc;
    @media (min-width: 320px) {
      height: 80px;
      margin-bottom: 5%;
      margin-left: 0;
      margin-right: 0;
      .image-a {
        width: 60px;
        height: 60px;
        margin-top: 10px;
        margin-left: 10px;
      }
      .text_a {
        padding-left: 20px;
        flex-direction: row !important;
        justify-content: space-between !important;
        align-items: center !important;
        width: 70%;

        p {
          font-size: 16px;
          margin: 0px;
          padding: 0px;
          color: #343a40;
          font-weight: 600;
        }
        .values {
          padding-left: 10px;
          font-size: 28px;
          color: #343a40;
          font-weight: bold;
        }
      }
    }
    @media (min-width: 768px) {
      margin-right: 14px;
      height: 108px;
      margin-bottom: 0;
      .image-a {
        height: 70px;
        width: 70px;
        margin-top: 16px;
        margin-left: 16px;
      }
      .text_a {
        padding-left: 16px;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-right: 16px;
        flex-direction: column !important;
        justify-content: space-between !important;
        align-items: start !important;
        .values {
          padding-left: 0px;
        }
      }
    }
    @media (min-width: 992px) {
      margin-right: 20.665px;
    }
    @media (min-width: 1200px) {
      .image-a {
        height: 70px;
        width: 70px;
        margin-top: 16px;
        margin-left: 22px;
      }
      .text_a {
        padding-left: 9.3px;
        padding-top: 21px;
        padding-bottom: 23px;
        padding-right: 0px;
      }
    }
  }
  .cardboard_b {
    border-radius: 8px;
    background-color: #fdf3f5;
    border: 1px solid #f291a5;
    @media (min-width: 320px) {
      height: 80px;
      margin-bottom: 5%;
      margin-left: 0;
      margin-right: 0;
      .image-b {
        width: 60px;
        height: 60px;
        margin-top: 10px;
        margin-left: 10px;
      }
      .text_b {
        padding-left: 20px;
        flex-direction: row !important;
        justify-content: space-between !important;
        align-items: center !important;
        width: 70%;

        p {
          font-size: 16px;
          margin: 0px;
          padding: 0px;
          color: #343a40;
          font-weight: 600;
        }
        .values {
          padding-left: 10px;
          font-size: 28px;
          color: #343a40;
          font-weight: bold;
        }
      }
    }
    @media (min-width: 768px) {
      margin-right: 7px;
      margin-left: 7px;
      height: 108px;
      margin-bottom: 0;
      .image-b {
        height: 70px;
        width: 70px;
        margin-top: 16px;
        margin-left: 16px;
      }
      .text_b {
        padding-left: 16px;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-right: 16px;
        flex-direction: column !important;
        justify-content: space-between !important;
        align-items: start !important;
        .values {
          padding-left: 0px;
        }
      }
    }
    @media (min-width: 992px) {
      margin-right: 10.335px;
      margin-left: 10.335px;
      .image-b {
        height: 70px;
        width: 70px;
        margin-top: 16px;
        margin-left: 16px;
      }
    }
    @media (min-width: 1200px) {
      .image-b {
        height: 69px;
        width: 69px;
        margin-top: 17px;
        margin-left: 20px;
      }
      .text_b {
        padding-left: 18px;
        padding-top: 23px;
        padding-bottom: 23px;
        padding-right: 0px;
      }
    }
  }
  .cardboard_c {
    border-radius: 8px;
    background-color: #fefce8;
    border: 1px solid #d8d186;
    @media (min-width: 320px) {
      height: 80px;
      margin-bottom: 5%;
      margin-left: 0;
      margin-right: 0;
      .image-c {
        width: 50px;
        height: 50px;
        margin-top: 15px;
        margin-left: 15px;
      }
      .text_c {
        padding-left: 20px;
        flex-direction: row !important;
        justify-content: space-between !important;
        align-items: center !important;
        width: 70%;

        p {
          font-size: 16px;
          margin: 0px;
          padding: 0px;
          color: #343a40;
          font-weight: 600;
        }
        .values {
          padding-left: 10px;
          font-size: 28px;
          color: #343a40;
          font-weight: bold;
        }
      }
    }
    @media (min-width: 768px) {
      margin-left: 14px;
      height: 108px;
      margin-bottom: 0;
      .image-c {
        height: 70px;
        width: 60px;
        margin-top: 16px;
        margin-left: 16px;
      }

      .text_c {
        padding-left: 16px;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-right: 16px;
        flex-direction: column !important;
        justify-content: space-between !important;
        align-items: start !important;
        .values {
          padding-left: 0px;
        }
      }
    }
    @media (min-width: 992px) {
      margin-left: 20.665px;

      .image-c {
        height: 70px;
        width: 60px;
        margin-top: 16px;
        margin-left: 16px;
      }
      .text_c {
        padding-left: 16px;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-right: 16px;
      }
    }
    @media (min-width: 1200px) {
      .image-c {
        height: 55px;
        width: 55px;
        margin-top: 26px;
        margin-left: 25px;
      }
      .text_c {
        padding-left: 23px;
        padding-top: 23px;
        padding-bottom: 22px;
        padding-right: 0px;
        span {
          display: none;
        }
      }
    }
  }
`;
export default CardsWrapper;
