/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import Loader from "components/Loader";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PiList from "services/api/GetPiApi";
import Pagination, { IPagination } from "components/Pagination";
import { PiWrapper } from "./styled";

function PiTable(props: any) {
  const { setActive } = props;
  const projectId = localStorage.getItem("projectId");
  const piStatus = ["Active", "Inactive"];
  let flag = false;
  const createPiState = () => {
    setActive("CreatePi");
  };
  const editPiState = () => {
    setActive("EditPi");
  };
  const [DataArr, setDataArr] = useState<any>([]);
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [paginate, setPagination] = useState<IPagination>({
    pageNumber,
    pageSize: 10,
    totalCount: 20,
    totalPages: 0,
    isFirst: true,
    isLast: true,
    setPageNumber,
    paginationSetLimit: 10,
  });
  const preventFlag = localStorage.getItem("preventFlag");
  const hidden = preventFlag === "true" ? true : false;

  const getPi = async () => {
    await PiList(projectId, pageNumber)
      .then((data: any) => {
        setDataArr((oldArray: any) => [...oldArray, data.data.Pi]);
        data.data.Pi.forEach((pi: any) => {
          if (pi.piStatus === true) {
            flag = true;
          }
        });
        if (flag === true) {
          localStorage.setItem("piStatus", "Active");
        } else {
          localStorage.setItem("piStatus", "Inactive");
        }
        setPagination((oldpaginate: IPagination) => ({
          ...oldpaginate,
          ...data._pagination,
        }));
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  };

  const currentDate = new Date(Date.now());

  useEffect(() => {
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    setDataArr([]);
    getPi();
  }, [pageNumber]);

  while (DataArr[0] === undefined) {
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    return <Loader />;
  }

  return (
    <PiWrapper id="maincontent container-fluid d-flex justify-content-between">
      <div
        className={`d-flex pb-4 w-100 ${
          hidden ? "editNav-hidden" : "editNav"
        } justify-content-end`}
      >
        <div className="d-flex createPi">
          <button
            type="button"
            disabled={props.onHoldProp}
            className="btn btn-round btn-primary btn-height d-flex createPiButton"
            onClick={createPiState}
          >
            Create Pi
          </button>
        </div>
      </div>
      <div>
        <table className="aui-responsive-status-table">
          <thead>
            <tr>
              <th scope="col">
                <div className="aui-th">Pi Name</div>
              </th>
              <th scope="col">
                <div className="aui-th justify-content-lg-center">
                  Start Date
                </div>
              </th>
              <th scope="col" className="text-lg-center">
                <div className="aui-th justify-content-lg-center">End Date</div>
              </th>
              <th scope="col">
                <div className="aui-th justify-content-lg-center">Status</div>
              </th>
              <th scope="col">
                <div className="aui-th justify-content-lg-center">Budget</div>
              </th>
              <th scope="col">
                <div className="aui-th justify-content-center">Pi Progress</div>
              </th>
            </tr>
          </thead>
          <tbody hidden={!(DataArr[0].length === 0)}>
            <tr className="aui-status-table-emptystate">
              <td colSpan={6}>
                <div className="aui-td">No Data</div>
              </td>
            </tr>
          </tbody>
          <tbody>
            {DataArr[0].slice(0).map((pi: any) => {
              const Date1 = new Date(pi.startAt);
              const Date2 = new Date(pi.endAt);
              const daysInitial = Math.round(
                Math.abs(
                  (Date2.getTime() - Date1.getTime()) / (1000 * 60 * 60 * 24)
                )
              );
              const daysRemaining = Math.round(
                Math.abs(
                  (Date2.getTime() - currentDate.getTime()) /
                    (1000 * 60 * 60 * 24)
                )
              );

              let progress = Math.round(
                (Math.abs(daysInitial - daysRemaining) / daysInitial) * 100
              );

              if (currentDate > Date2) {
                progress = 100;
              }

              if (currentDate > Date2 && pi.piStatus) {
                props.disableAddReport(true);
              }

              if (currentDate <= Date2 && pi.piStatus) {
                props.disableAddReport(false);
              }

              return (
                <tr
                  key={pi.id}
                  className={`aui-table-status-${
                    pi.piStatus ? "success" : "deactivated"
                  }`}
                >
                  <td data-title="Pi Name">
                    <div className="aui-td pl-2">
                      <button
                        type="button"
                        disabled={hidden}
                        onClick={() => {
                          localStorage.setItem("piId", pi.id);
                          editPiState();
                        }}
                        className="pi-title"
                        key={pi.id}
                      >
                        {pi.name}
                      </button>
                    </div>
                  </td>
                  <td data-title="Start Date">
                    <div
                      className="aui-td justify-content-lg-center"
                      key={pi.id}
                    >
                      {pi.startAt
                        .substring(0, 10)
                        .split("-")
                        .reverse()
                        .join("-")}
                    </div>
                  </td>
                  <td data-title="End Date">
                    <div
                      className="aui-td justify-content-lg-center"
                      key={pi.id}
                    >
                      {pi.endAt.substring(0, 10).split("-").reverse().join("-")}
                    </div>
                  </td>
                  <td data-title="Status">
                    <div
                      className="aui-td justify-content-lg-center"
                      key={pi.id}
                    >
                      {pi.piStatus ? piStatus[0] : piStatus[1]}
                    </div>
                  </td>
                  <td data-title="Budget">
                    <div
                      className="aui-td budget-value justify-content-lg-center"
                      key={pi.id}
                    >
                      {pi.piBudget}
                    </div>
                  </td>
                  <td data-title="Pi Progress">
                    <div className="aui-td">
                      <progress
                        id="progressBar"
                        value={progress}
                        max="100"
                        key={pi.id}
                      >
                        {progress}
                      </progress>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="d-flex justify-content-end">
          <Pagination
            pageNumber={paginate.pageNumber}
            pageSize={paginate.pageSize}
            setPageNumber={paginate.setPageNumber}
            paginationSetLimit={paginate.paginationSetLimit}
            totalCount={paginate.totalCount}
            totalPages={paginate.totalPages}
            isFirst={paginate.isFirst}
            isLast={paginate.isLast}
          />
        </div>
      </div>
    </PiWrapper>
  );
}

export default PiTable;
