/* eslint-disable no-console */
import axios from "axios";
import CONFIG from "../../config";

const Login = async (email: string, password: string) => {
  return axios
    .post(
      `${
        CONFIG[process.env.REACT_APP_MY_ENV].apiEndPoints.account
      }/v1/adminLogin`,
      {
        email,
        password,
      }
    )
    .then((response) => {
      if (response.data.access_token) {
        localStorage.setItem(
          "user",
          JSON.stringify(response.data.access_token)
        );
        localStorage.setItem("email", response.data.email);
        localStorage.setItem("role", response.data.role);
        console.log(JSON.stringify(response.data.access_token));
      }
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export default Login;
