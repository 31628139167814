import styled from "styled-components";

export const EditPiWrapper = styled.div`
  width: 100%;
  font: normal normal 400 16px/23px Montserrat;
  form {
    width: 100%;
    padding-inline: 0px;
    & .row {
      margin-left: 0px;
      margin-right: 0px;
    }

    & .dateTags {
      margin-bottom: -25px;
      & p {
        font-size: 12px;
        color: #222328;
      }
      @media only screen and (min-width: 992px) {
        width: 400px;
      }
    }
    & input {
      border: 1px solid #bcc3ca;
      @media only screen and (min-width: 992px) {
        width: 400px;
      }
    }
    & input[type="range"] {
      width: 100%;
      -webkit-appearance: none;
      z-index: 1;
      overflow: hidden;
      border-radius: 5px;
      height: 10px;
      outline: none;
      background: #ececec 0% 0% no-repeat padding-box;
      box-shadow: inset 0px 3px 6px #00000029;
      @media only screen and (min-width: 992px) {
        width: 400px;
        height: 10px;
      }
    }
    & input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      position: relative;
      width: 14px;
      height: 14px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 4px #00000029;
      border: 0.5px solid #00000029;
      border-radius: 5px;
      opacity: 1;
      box-shadow: -400px 0 0 400px #c10e21;
      cursor: pointer;
      z-index: 2;
    }

    & input[type="range"]::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      color: #ececec;
      box-shadow: inset 0px 3px 6px #00000029;
    }
    & .sliderValue {
      width: 100%;
      margin-bottom: 5px;
    }
    & .sliderValue span {
      font-size: 13px;
      padding-top: 2px;
      font-weight: 500;
      z-index: 2;
    }
    & .duration {
      color: #c10e21;
    }

    & button {
      margin-right: 20px;
    }
    & .bottomHr {
      margin-top: 50px;
      margin-bottom: 20px;
    }
  }
  & .btngroupdiv {
    display: flex;
    flex-direction: column-reverse;
    @media only screen and (min-width: 550px) {
      flex-direction: row;
    }
  }
  & .main-content {
    position: relative;
    width: 100%;
    height: 700px;
    flex-direction: column;
    @media only screen and (min-width: 992px) {
      display: flex !important;
      flex-direction: row;
      height: auto;
    }
  }
  & .currency-button {
    border-top: none !important;
    border-left: none !important;
    border-bottom: none !important;
    border-right: 1px solid #bcc3ca !important;
    border-radius: 0px !important;
  }
  & .budget-border {
    border: 1px solid #bcc3ca !important;
    width: 400px;
  }
`;

export const AnotherWraper = styled.div``;
