import styled from "styled-components";

export const AsideWrapper = styled.aside`
  flex-direction: column;
  background-color: #ffffff;
  width: 100%;
  @media (min-width: 992px) {
    background-color: #edf2f6;
    min-width: 250px;
    max-width: 250px;
    width: 250px;
  }
  & .navbar-expand-lg {
    & .aside-title {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 20px 0px;
      background-color: #edf2f6;
      @media (min-width: 992px) {
        display: block;
        margin: 0px;
      }
      & .position-hamburger {
        width: 100%;
        background-color: #edf2f6;
      }
      & #sideNavbar ul li a.active {
        background: #343a40 !important;
        border-radius: 4px;
      }
      & #sideNavbar ul li {
        margin-inline: 10px;
        border-bottom: 1px solid #707070;
        font: normal normal 500 16px/23px Montserrat;
        letter-spacing: 0px;
        opacity: 1;
      }
      & #sideNavbar ul li:last-child {
        display: flex;
        justify-content: flex-end;
        font: normal normal normal 16px Montserrat;
        margin-inline: 10px;
        border: none;
        letter-spacing: 0px;
        opacity: 1;
      }
      & #sideNavbar ul li:last-child button {
        padding: 0;
        margin-top: 10px;
        margin-right: 20px;
      }
    }
    & .firstDiv {
      display: flex;
      position: absolute;
      left: 20px;
      top: 5px;
      padding-inline: 0px;
      @media (min-width: 992px) {
        position: relative;
        top: 18px;
        left: 0;
        padding-inline: 18px 24px;
      }
      & img {
        width: 120px;
        height: 72px;
      }
    }
  }
  nav#MyAccountNav div#MyAccount ul li.account-list {
    border-bottom: none;
  }
`;

export const TopNavWrapper = styled.nav`
  padding-top: 30px;
  display: block;
  & .search-container {
    display: flex;
    justify-content: space-between;
    flex-basis: auto;
    & form {
      width: 100%;
      & input {
        width: 100%;
        padding: 5px;
        border: 1px solid #bcc3ca;
        opacity: 1;
        color: black;
      }
    }
    & .accounts {
      & p {
        margin-top: 10px;
      }
    }
  }
  @media (min-width: 992px) {
    display: none;
  }
`;

export const TopNavWrapperAccount = styled.nav`
  display: block;
  padding-inline: 10px;
  font: normal normal normal 16px Montserrat;
  @media (min-width: 992px) {
    display: none;
  }
  div {
    & button.btn {
      font: normal 500 normal 16px Montserrat;
    }
  }
  div {
    & ul {
      & li {
        & a {
          font: normal 400 normal 16px Montserrat;
          border: none;
          padding: 5px;
        }
      }
    }
  }
`;
