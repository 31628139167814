/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-debugger */
/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { showToast } from "components/Toast/toast.slice";
import UpdateReportsApi from "services/api/UpdateReportApi";
import GetReportById from "services/api/GetReportById";
import PiList from "services/api/GetPiApi";
import Loader from "components/Loader";
import CONSTANTS from "../../../common/constants";
import { EditReportWrapper } from "./styled";

function EditProject(props: any) {
  const { setActiveReport } = props;
  const ViewProjectReportState = () => {
    setActiveReport("ReportTable");
  };
  const id = localStorage.getItem("reportId");
  const projectID = localStorage.getItem("projectId");
  const [selected, setSelected] = useState<any>(true);
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();

  const [toggle, setToggle] = useState({
    scope: CONSTANTS.GREEN,
    quality: CONSTANTS.GREEN,
    budget: CONSTANTS.GREEN,
    schedule: CONSTANTS.GREEN,
    health: CONSTANTS.GREEN,
  });
  const [DataArr, setDataArr] = useState<any>([]);
  const previousReportId = localStorage.getItem("reportId");
  const [reportForm, setReportForm] = useState({
    budget: CONSTANTS.GOODCHANGE,
    budget_comment: "",
    schedule: CONSTANTS.GOODCHANGE,
    schedule_comment: "",
    overall_health: CONSTANTS.GOODCHANGE,
    overall_health_comment: "",
    overall_health_percentage: 0,
    scope: CONSTANTS.GOODCHANGE,
    scope_comment: "",
    pi_id: null,
    projectId: Number(projectID),
    start_date: "",
    end_date: "",
    quality: CONSTANTS.GOODCHANGE,
    quality_comment: "",
    week: 0,
    reportId: Number(previousReportId),
  });
  const getPi = async () => {
    await PiList(projectID, null)
      .then((data: any) => {
        setDataArr((oldArray: any) => [...oldArray, data.data.Pi]);
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      })
      .catch((error) => {
        console.log(error.response.data.error);
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      });
  };
  const getReport = async () => {
    await GetReportById(id)
      .then((data: any) => {
        setReportForm({
          ...reportForm,
          pi_id: data.data.Report.pi_id,
          projectId: data.data.Report.projectId,
          scope: data.data.Report.scope,
          scope_comment: data.data.Report.scope_comment,
          budget: data.data.Report.budget,
          budget_comment: data.data.Report.budget_comment,
          schedule: data.data.Report.schedule,
          schedule_comment: data.data.Report.schedule_comment,
          overall_health: data.data.Report.overall_health,
          overall_health_comment: data.data.Report.overall_health_comment,
          overall_health_percentage: data.data.Report.overall_health_percentage,
          week: data.data.Report.week,
          start_date: data.data.Report.start_date,
          end_date: data.data.Report.end_date,
          quality: data.data.Report.quality,
          quality_comment: data.data.Report.quality_comment,
          reportId: data.data.Report.id,
        });
        console.log(data.data.Report.pi_id);
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      });
  };

  const onSave = async (e: any) => {
    e.preventDefault();
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    await UpdateReportsApi(reportForm, id)
      .then((data: any) => {
        ViewProjectReportState();
        const toast = {
          visibility: true,
          message: "Project Updated Successfully",
          title: "Success",
          type: "success",
        };
        dispatch(showToast(toast));
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      })
      .catch((error: any) => {
        console.log(error);
        console.log(error.response);
        const invalidReportToast = {
          visibility: true,
          message: "Select PI before submitting",
          title: "Failure",
          type: "danger",
        };
        if (error.response === undefined) {
          console.log("empty date");
          dispatch(showToast(invalidReportToast));
          dispatch(setPageLoadingStatus({ isPageLoading: false }));
          return;
        }
        const toast = {
          visibility: true,
          message: error.response.data.error.details[0].message,
          title: "Failure",
          type: "danger",
        };
        dispatch(showToast(toast));
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      });
  };
  useEffect(() => {
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    getPi();
    getReport();
  }, []);
  useEffect(() => {
    setToggle({
      scope: reportForm.scope,
      quality: reportForm.quality,
      budget: reportForm.budget,
      schedule: reportForm.schedule,
      health: reportForm.overall_health,
    });
  }, [reportForm]);
  while (DataArr[0] === undefined) {
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    return <Loader />;
  }

  dispatch(setPageLoadingStatus({ isPageLoading: false }));
  const startDate = new Date(reportForm.start_date);
  const longMonthStart = startDate.toLocaleString("en-us", {
    month: "long",
  });
  const endDate = new Date(reportForm.end_date);
  const longMonthEnd = endDate.toLocaleString("en-us", {
    month: "long",
  });

  return (
    <EditReportWrapper className="text">
      <div>
        <div className="d-flex justify-content-between align-items-end headingDiv">
          <div className="">
            <span className="weekNumber">Week {reportForm.week} </span>{" "}
            <span className="weekDate">
              {longMonthStart} {startDate.getDate()} - {longMonthEnd}{" "}
              {endDate.getDate()}
            </span>
          </div>
          <button
            type="button"
            className="btn editicon-btn"
            disabled={props.onHoldProp || props.reportEdit}
            onClick={() => {
              setSelected(!selected);
            }}
          >
            <i className="aha-icon-edit edit-icon" />
          </button>
        </div>
        <form className="form">
          <div
            className={`${
              !selected ? "inputElementsDiv" : "inputElementsDivDisabled"
            }`}
          >
            <div className={`form-entry ${selected ? "" : "scopes"}`}>
              <div className=" d-flex  form-group row required  col-12 justify-content-between pl-0 pr-0 mr-0 ml-0 forminput">
                <label
                  htmlFor="scope"
                  className="col-2 col-form-label scope pl-0 pr-0 pt-0"
                >
                  Scope{" "}
                </label>
                <textarea
                  className="form-control col-md-7 textarea"
                  id="scope"
                  placeholder="Add Comment"
                  disabled={selected}
                  defaultValue={reportForm.scope_comment}
                  onChange={(event) => {
                    setReportForm({
                      ...reportForm,
                      scope_comment: event.target.value,
                    });
                  }}
                />

                <div className="toggle-buttons d-flex col-2 align-items-top pl-0 pr-0">
                  <button
                    className={
                      toggle.scope === CONSTANTS.GOODCHANGE ||
                      toggle.scope === CONSTANTS.NOCHANGEGOOD
                        ? "toggle-buttons-green col-6 text-center align-middle text-white pt-0 pb-0 pr-0 pl-0 green"
                        : "toggle-buttons-green col-6 text-center align-middle pt-0 pb-0 pr-0 pl-0 "
                    }
                    aria-label="green toggle"
                    type="button"
                    id="scope"
                    disabled={selected}
                    onClick={() => {
                      setToggle({
                        ...toggle,
                        scope: "green",
                      });
                      setReportForm({
                        ...reportForm,
                        scope: CONSTANTS.GOODCHANGE,
                      });
                    }}
                  >
                    <p>Green</p>
                  </button>
                  <button
                    className={
                      toggle.scope === CONSTANTS.NOCHANGEBAD ||
                      toggle.scope === CONSTANTS.BADCHANGE
                        ? "toggle-buttons-red col-6 text-center align-middle text-white pt-0 pb-0 pr-0 pl-0 red"
                        : "toggle-buttons-red col-6 text-center align-middle pt-0 pb-0 pr-0 pl-0 "
                    }
                    aria-label="red toggle"
                    type="button"
                    id="scope"
                    disabled={selected}
                    onClick={() => {
                      setToggle({
                        ...toggle,
                        scope: "red",
                      });
                      setReportForm({
                        ...reportForm,
                        scope: CONSTANTS.BADCHANGE,
                      });
                    }}
                  >
                    <p>Red</p>
                  </button>
                </div>
              </div>
            </div>
            <div className="form-entry">
              <div className=" d-flex  form-group row required  col-sm-12 justify-content-between pl-0 pr-0 mr-0 ml-0 forminput">
                <label
                  htmlFor="quality"
                  className="col-2 col-form-label quality pl-0 pr-0 pt-0"
                >
                  Quality{" "}
                </label>
                <textarea
                  className="form-control col-md-7 textarea"
                  id="scope"
                  placeholder="Add Comment"
                  disabled={selected}
                  defaultValue={reportForm.quality_comment}
                  onChange={(event) => {
                    setReportForm({
                      ...reportForm,
                      quality_comment: event.target.value,
                    });
                  }}
                />

                <div className="toggle-buttons d-flex col-2 align-items-top pl-0 pr-0">
                  <button
                    className={
                      toggle.quality === CONSTANTS.GOODCHANGE ||
                      toggle.quality === CONSTANTS.NOCHANGEGOOD
                        ? "toggle-buttons-green col-6 text-center align-middle text-white pt-0 pb-0 pr-0 pl-0 green"
                        : "toggle-buttons-green col-6 text-center align-middle pt-0 pb-0 pr-0 pl-0 "
                    }
                    aria-label="green toggle"
                    type="button"
                    id="quality"
                    disabled={selected}
                    onClick={() => {
                      setToggle({
                        ...toggle,
                        quality: "green",
                      });
                      setReportForm({
                        ...reportForm,
                        quality: CONSTANTS.GOODCHANGE,
                      });
                    }}
                  >
                    <p>Green</p>
                  </button>
                  <button
                    className={
                      toggle.quality === CONSTANTS.NOCHANGEBAD ||
                      toggle.quality === CONSTANTS.BADCHANGE
                        ? "toggle-buttons-red col-6 text-center align-middle text-white pt-0 pb-0 pr-0 pl-0 red"
                        : "toggle-buttons-red col-6 text-center align-middle pt-0 pb-0 pr-0 pl-0 "
                    }
                    aria-label="red toggle"
                    type="button"
                    id="quality"
                    disabled={selected}
                    onClick={() => {
                      setToggle({
                        ...toggle,
                        quality: "red",
                      });
                      setReportForm({
                        ...reportForm,
                        quality: CONSTANTS.BADCHANGE,
                      });
                    }}
                  >
                    <p>Red</p>
                  </button>
                </div>
              </div>
            </div>
            <div className={`form-entry ${selected ? "" : "scopes"}`}>
              <div className=" d-flex  form-group row required  col-12 justify-content-between pl-0 pr-0 mr-0 ml-0 forminput">
                <label
                  htmlFor="budget"
                  className="col-2 col-form-label budget pl-0 pr-0 pt-0"
                >
                  Budget{" "}
                </label>
                <textarea
                  className="form-control col-md-7 textarea"
                  id="scope"
                  placeholder="Add Comment"
                  disabled={selected}
                  defaultValue={reportForm.budget_comment}
                  onChange={(event) => {
                    setReportForm({
                      ...reportForm,
                      budget_comment: event.target.value,
                    });
                  }}
                />
                <div className="toggle-buttons d-flex col-2 align-items-top pl-0 pr-0">
                  <button
                    className={
                      toggle.budget === CONSTANTS.GOODCHANGE ||
                      toggle.budget === CONSTANTS.NOCHANGEGOOD
                        ? "toggle-buttons-green col-6 text-center align-middle text-white pt-0 pb-0 pr-0 pl-0 green"
                        : "toggle-buttons-green col-6 text-center align-middle pt-0 pb-0 pr-0 pl-0 "
                    }
                    aria-label="green toggle"
                    type="button"
                    id="budget"
                    disabled={selected}
                    onClick={() => {
                      setToggle({
                        ...toggle,
                        budget: "green",
                      });
                      setReportForm({
                        ...reportForm,
                        budget: CONSTANTS.GOODCHANGE,
                      });
                    }}
                  >
                    <p>Green</p>
                  </button>
                  <button
                    className={
                      toggle.budget === CONSTANTS.NOCHANGEBAD ||
                      toggle.budget === CONSTANTS.BADCHANGE
                        ? "toggle-buttons-red col-6 text-center align-middle text-white pt-0 pb-0 pr-0 pl-0 red"
                        : "toggle-buttons-red col-6 text-center align-middle pt-0 pb-0 pr-0 pl-0 "
                    }
                    aria-label="red toggle"
                    type="button"
                    id="budget"
                    disabled={selected}
                    onClick={() => {
                      setToggle({
                        ...toggle,
                        budget: "red",
                      });
                      setReportForm({
                        ...reportForm,
                        budget: CONSTANTS.BADCHANGE,
                      });
                    }}
                  >
                    <p>Red</p>
                  </button>
                </div>
              </div>
            </div>
            <div className="form-entry">
              <div className=" d-flex  form-group row required  col-sm-12 justify-content-between pl-0 pr-0 mr-0 ml-0 forminput">
                <label
                  htmlFor="schedule"
                  className="col-2 col-form-label quality pl-0 pr-0 pt-0"
                >
                  Schedule{" "}
                </label>
                <textarea
                  className="form-control col-md-7 textarea"
                  id="schedule"
                  placeholder="Add Comment"
                  disabled={selected}
                  defaultValue={reportForm.schedule_comment}
                  onChange={(event) => {
                    setReportForm({
                      ...reportForm,
                      schedule_comment: event.target.value,
                    });
                  }}
                />

                <div className="toggle-buttons d-flex col-2 align-items-top pl-0 pr-0">
                  <button
                    className={
                      toggle.schedule === CONSTANTS.GOODCHANGE ||
                      toggle.schedule === CONSTANTS.NOCHANGEGOOD
                        ? "toggle-buttons-green col-6 text-center align-middle text-white pt-0 pb-0 pr-0 pl-0 green"
                        : "toggle-buttons-green col-6 text-center align-middle pt-0 pb-0 pr-0 pl-0 "
                    }
                    aria-label="green toggle"
                    type="button"
                    id="schedule"
                    disabled={selected}
                    onClick={() => {
                      setToggle({
                        ...toggle,
                        schedule: "green",
                      });
                      setReportForm({
                        ...reportForm,
                        schedule: CONSTANTS.GOODCHANGE,
                      });
                    }}
                  >
                    <p>Green</p>
                  </button>
                  <button
                    className={
                      toggle.schedule === CONSTANTS.NOCHANGEBAD ||
                      toggle.schedule === CONSTANTS.BADCHANGE
                        ? "toggle-buttons-red col-6 text-center align-middle text-white pt-0 pb-0 pr-0 pl-0 red"
                        : "toggle-buttons-red col-6 text-center align-middle pt-0 pb-0 pr-0 pl-0 "
                    }
                    aria-label="red toggle"
                    type="button"
                    id="schedule"
                    disabled={selected}
                    onClick={() => {
                      setToggle({
                        ...toggle,
                        schedule: "red",
                      });
                      setReportForm({
                        ...reportForm,
                        schedule: CONSTANTS.BADCHANGE,
                      });
                    }}
                  >
                    <p>Red</p>
                  </button>
                </div>
              </div>
            </div>
            <div className={`form-entry ${selected ? "" : "scopes"}`}>
              <div className=" d-flex  form-group row required  col-sm-12 justify-content-between pl-0 pr-0 mr-0 ml-0 forminput pb-0 mb-0">
                <label
                  htmlFor="overall-health"
                  className="col-2 col-form-label overall-health pl-0 pr-0 pt-0 pb-0"
                >
                  Overall Health (%){" "}
                </label>

                <input
                  type="number"
                  className="form-control col-md-7"
                  id="overall-health-percentage"
                  aria-label="enter overall-health"
                  max={100}
                  disabled={selected}
                  defaultValue={reportForm.overall_health_percentage}
                  onChange={(event) => {
                    setReportForm({
                      ...reportForm,
                      overall_health_percentage: Number(event.target.value),
                    });
                  }}
                />

                <div className="toggle-buttons d-flex col-2 align-items-top pl-0 pr-0">
                  <button
                    className={
                      toggle.health === CONSTANTS.GOODCHANGE ||
                      toggle.health === CONSTANTS.NOCHANGEGOOD
                        ? "toggle-buttons-green col-6 text-center align-middle text-white pt-0 pb-0 pr-0 pl-0 green"
                        : "toggle-buttons-green col-6 text-center align-middle pt-0 pb-0 pr-0 pl-0 "
                    }
                    aria-label="green toggle"
                    type="button"
                    id="overall-health"
                    disabled={selected}
                    onClick={() => {
                      setToggle({
                        ...toggle,
                        health: "green",
                      });
                      setReportForm({
                        ...reportForm,
                        overall_health: CONSTANTS.GOODCHANGE,
                      });
                    }}
                  >
                    <p>Green</p>
                  </button>
                  <button
                    className={
                      toggle.health === CONSTANTS.NOCHANGEBAD ||
                      toggle.health === CONSTANTS.BADCHANGE
                        ? "toggle-buttons-red col-6 text-center align-middle text-white pt-0 pb-0 pr-0 pl-0 red"
                        : "toggle-buttons-red col-6 text-center align-middle pt-0 pb-0 pr-0 pl-0 "
                    }
                    aria-label="red toggle"
                    type="button"
                    id="overall-health"
                    disabled={selected}
                    onClick={() => {
                      setToggle({
                        ...toggle,
                        health: "red",
                      });
                      setReportForm({
                        ...reportForm,
                        overall_health: CONSTANTS.BADCHANGE,
                      });
                    }}
                  >
                    <p>Red</p>
                  </button>
                </div>
              </div>
              <div className="form-group row d-flex justify-content-center col-sm-12 pl-0 pr-0 mb-0 ml-0 mr-0 overall-health">
                <textarea
                  className="form-control col-md-7 textarea"
                  id="overall-health"
                  placeholder="Add Comment"
                  defaultValue={reportForm.overall_health_comment}
                  disabled={selected}
                  onChange={(event) => {
                    setReportForm({
                      ...reportForm,
                      overall_health_comment: event.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="lines" />
          <div className="buttons d-flex justify-content-end flex-wrap">
            <button
              className="btn cancel-button btn-round btn-secondary text-center p-0"
              type="button"
              onClick={() => {
                props.disableReportEdit(false);
                ViewProjectReportState();
                setDisabled(!disabled);
              }}
            >
              Back
            </button>
            <button
              className="btn submit-button btn-round btn-primary text-center p-0"
              type="submit"
              disabled={selected}
              onClick={(e) => {
                onSave(e);
              }}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </EditReportWrapper>
  );
}

export default EditProject;
