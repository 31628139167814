import styled from "styled-components";

export const ProjectReportWrapper = styled.div`
  font: normal normal 600 16px/23px Montserrat;
  width: 100%;
  height: auto;
  & td {
    & .description {
      font: normal normal normal 14px/13px Montserrat;
      margin-top: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.4;
      white-space: nowrap;
      display: block;
    }
  }
  & .arrow-img {
    width: 20px;
  }
  & .filter-bar {
    background-color: white;
  }
  & .hidden-filter-bar {
    display: none !important;
  }
  & .project-title {
    display: flex;
    color: #c10e21;
    text-decoration: underline;
    background-color: white;
    border: none;
    font: normal normal 600 16px/23px Montserrat;
    padding: 0px;
  }
`;

export const AnotherWraper = styled.div``;
