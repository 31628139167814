/* eslint-disable prettier/prettier */
import React from "react";
import AdminFooterWrapper from "./styled";

export const AdminFooter = () => {
  return (
    <AdminFooterWrapper className="aui-footer text-left">
      <div className="heading1">
        National Center, 7272 Greenville Ave., Dallas, TX 75231 | Customer Service: 1-800-AHA-USA-1, 1-800-242-8721
      </div>
      <div className="body1">
        &copy;2020 American Heart Association, Inc. All rights reserved.
        Unauthorized use prohibited. The American Heart Association is a
        qualified 501(c)(3) tax-exempt organization. *Red Dress ™ DHHS, Go Red ™
        AHA ; National Wear Red Day® is a registered trademark.
      </div>
    </AdminFooterWrapper>
  );
};
export default AdminFooter;
