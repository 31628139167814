/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import React from "react";
import PaginationStyle from "./styled";

export interface IPagination {
  pageNumber: number;
  pageSize: number;
  totalCount?: number;
  totalPages?: number;
  isFirst: boolean;
  isLast: boolean;
  setPageNumber: any;
  paginationSetLimit: number;
}

const Pagination = ({
  pageNumber,
  pageSize,
  totalCount,
  totalPages,
  isFirst,
  isLast,
  setPageNumber,
  paginationSetLimit,
}: IPagination) => {
  let paginationSetIndex = 0;
  let paginationSetMaxLimit = paginationSetLimit;

  if (totalPages && totalPages < paginationSetLimit) {
    paginationSetMaxLimit = totalPages;
  } else if (pageNumber / paginationSetLimit > 1) {
    paginationSetIndex =
      Math.floor(pageNumber / paginationSetLimit) * paginationSetLimit;
    if (Number(totalPages) - pageNumber < paginationSetLimit) {
      paginationSetMaxLimit = Number(totalPages) - paginationSetLimit;
    }
  } else if (totalPages === 0) {
    paginationSetMaxLimit = 1;
  }

  return (
    <PaginationStyle>
      <div className="d-flex mt-7 flex-row-reverse">
        <nav aria-label="Page navigation">
          <ul className="pagination aui-pagination">
            <li className="page-item">
              <button
                className={isFirst ? "page-link page-disabled" : "page-link"}
                aria-label="Previous"
                disabled={isFirst}
                onClick={() => setPageNumber(pageNumber - 1)}
                type="button"
              >
                <span aria-hidden="true">&laquo;</span>
              </button>
            </li>
            {[...Array(paginationSetMaxLimit)].map((x, i) => (
              <li
                key={i}
                className={
                  1 + i + paginationSetIndex === pageNumber
                    ? "page-item active"
                    : "page-item"
                }
              >
                <button
                  className="page-link"
                  onClick={() => {
                    setPageNumber(1 + i + paginationSetIndex);
                  }}
                  type="button"
                >
                  {1 + i + paginationSetIndex}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button
                className={isLast ? "page-link page-disabled" : "page-link"}
                aria-label="Next"
                disabled={isLast}
                onClick={() => {
                  setPageNumber(pageNumber + 1);
                }}
                type="button"
              >
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </PaginationStyle>
  );
};

export default Pagination;
