/* eslint-disable no-console */
import axios from "axios";
import React, { useState } from "react";
import CONFIG from "../../config";

const ProjectList = async (pageNumber: number) => {
  const bearerToken = localStorage.getItem("user") as string;
  const tenantId = 1;
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(bearerToken)}`,
      "X-Tenant-Id": tenantId,
    },
  };
  return axios
    .get(
      `${
        CONFIG[process.env.REACT_APP_MY_ENV].apiEndPoints.phrt
      }/v1/projects?pageNumber=${pageNumber}`,
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export default ProjectList;
