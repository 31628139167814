const config: any = {
  env: process.env.REACT_APP_MY_ENV || "dev",
  beta: {
    agreementFilePath: "https://d17zibtpdnrjam.cloudfront.net",
    apiEnspoints: {
      account: "https://api-dev-gateway-v1-phrt.impelsys.net/account",
      phrt: "https://api-dev-gateway-v1-phrt.impelsys.net/phrt",
    },
  },
  prod: {
    agreementFilePath: "https://d17zibtpdnrjam.cloudfront.net",
    apiEnspoints: {
      account: "https://api-dev-gateway-v1-phrt.impelsys.net/account",
      phrt: "https://api-dev-gateway-v1-phrt.impelsys.net/phrt",
    },
  },
  local: {
    agreementFilePath: "https://d17zibtpdnrjam.cloudfront.net",
    apiEndPoints: {
      account: "http://localhost:8080/account",
      phrt: "http://localhost:8080/phrt",
    },
  },
  dev: {
    apiEndPoints: {
      account: "https://api-dev-gateway-v1-phrt.impelsys.net/account",
      phrt: "https://api-dev-gateway-v1-phrt.impelsys.net/phrt",
    },
  },
};

export default config;
