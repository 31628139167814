import axios from "axios";
import CONFIG from "../../config";

const GetProjectCount = async () => {
  const bearerToken = localStorage.getItem("user") as string;
  if (bearerToken) {
    const tenantId = 1;
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(bearerToken)}`,
        "X-Tenant-Id": tenantId,
      },
    };
    return axios
      .get(
        `${
          CONFIG[process.env.REACT_APP_MY_ENV].apiEndPoints.phrt
        }/v1/projectsCount`,
        config
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }
  throw new Error("No token found");
};

export default GetProjectCount;
