/* eslint-disable prettier/prettier */
import React from "react";
import FooterWrapper from "./styled";

export const Footer = () => {
  return (
    <FooterWrapper className="aui-footer text-left">
      <p className="p-0 m-0">
        National Center, 7272 Greenville Ave., Dallas, TX 75231 | Customer Service: 1-800-AHA-USA-1, 1-800-242-8721
      </p>
      <p className="p-0 m-0">
        &copy;2020 American Heart Association, Inc. All rights reserved.
        Unauthorized use prohibited. The American Heart Association is a
        qualified 501(c)(3) tax-exempt organization. *Red Dress ™ DHHS, Go Red ™
        AHA ; National Wear Red Day® is a registered trademark.
      </p>
    </FooterWrapper>
  );
};
export default Footer;
