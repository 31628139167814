import styled from "styled-components";

const ProjectDetailWrapper = styled.div`
  & .confirmDiv {
    & button {
      margin-right: 15px;
    }
  }

  & .text-area-div {
    padding-left: 10px;
    padding-right: 10px;
  }
  & .confirmDivPause {
    margin-top: 15px;
    & button {
      margin-right: 15px;
    }
  }

  & .edit-buttons {
    display: none !important;
  }
  & .overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    height: 110vh;
    background: rgba(0, 0, 0, 0.56);
    overflow: auto;
    z-index: 3;
    justify-content: center;
    width: 110vw;
    align-items: center;
    margin-left: -30px;
    margin-top: -290px;
    @media (min-width: 550px) {
      margin-left: -60px;
      margin-top: -290px;
    }
    @media (min-width: 768px) {
      margin-left: -80px;
      margin-top: -290px;
    }
    @media (min-width: 992px) {
      margin-left: -380px;
      margin-top: -266px;
    }
  }
  & .background-overlay {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
  }
  & .overlay-hidden {
    display: none;
  }
  & .row {
    margin-right: 0px;
  }
  & main {
    & .edtbtn {
      height: 44px;
    }

    & .pause-button {
      margin-right: 10px;
    }
    & .edit-icon {
      color: #c10e21;
      font-size: 30px;
    }
    @media (min-width: 360px) {
      display: flex;
      flex-direction: column;

      & .editableForm {
        &.firstFormDiv {
          display: flex;
          & .firstP {
            font-weight: 600;
            border-bottom: 3px solid #c10e21;
            width: fit-content;
            padding-bottom: 5px;
          }
        }

        & input {
          border: 0.6000000238418579px solid #bcc3ca;
          color: black;
        }

        & input:disabled {
          background-color: white;
          cursor: default;
        }

        & textarea {
          border: 0.6000000238418579px solid #bcc3ca;
        }
      }

      & .bottomHr {
        margin-top: 40px;
      }

      & .formButtonDiv {
        display: flex;
        justify-content: flex-end;
        margin-top: 40px;

        & button {
          width: 128px;
          height: 40px;
          border-radius: 20px;
          margin-right: 20px;
          border: 1px solid #c10e21;
        }
      }
    }
    & .buttoneditend {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      @media (min-width: 550px) {
        flex-direction: row;
        justify-content: start;
      }
    }
    & .editPencil {
      width: 70px !important;
      display: flex;
      justify-content: center;
      margin-top: 3px;
    }
    & .edtbtn {
      display: flex;
      padding-inline: 20px;
      margin-top: 5px;
      align-self: flex-end;
      text-align: center;
      padding-top: 12px;
      @media (min-width: 550px) {
        align-self: auto;
      }
    }
    & .firstFormDiv {
      flex-direction: column;
      @media (min-width: 550px) {
        flex-direction: row;
      }
    }
    & .firstP {
      font-weight: 600;
      border-bottom: 3px solid #c10e21;
      width: fit-content;
      padding-bottom: 5px;
      margin-top: 15px;
    }
    & input {
      border: 0.6000000238418579px solid #bcc3ca;
    }
    & input:disabled {
      background-color: white;
      border: none;
    }
    & textarea {
      border: 0.6000000238418579px solid #bcc3ca;
    }
    & textarea:disabled {
      background-color: white;
      border: none;
      cursor: default;
    }
    & .formButtonDiv {
      display: flex;
      margin: 10px;
      float: right;
      flex-direction: row;
      @media (max-width: 550px) {
        flex-direction: column-reverse;
        width: 100%;
      }
      & button {
        width: 128px;
        height: 40px;
        border-radius: 20px;
        border: 1px solid #c10e21;
        margin-left: 20px;
        @media (max-width: 550px) {
          margin-left: 10px;
          margin-top: 20px;
          width: 100%;
        }
      }

      & .cancelBtn {
        background: white;
        color: #c10e21;
        width: 100% !important;
        @media (min-width: 550px) {
          color: black;
        }
      }

      & .saveBtn {
        background: #c10e21;
        color: white;
        border: none;
        @media (max-width: 320px) {
          margin-top: 10px;
        }
      }
    }

    & .formButtonDivVisible {
      display: flex;
      margin: 10px;
      float: left;
      & button {
        width: 128px;
        height: 40px;
        margin-right: 20px;
        border: none;
        background: white;
        color: #c10e21;
        font-weight: 600;
        @media (max-width: 320px) {
          margin-left: auto;
          margin-right: auto;
          width: 80%;
        }
      }
    }
  }
`;

export default ProjectDetailWrapper;
