/* eslint-disable no-console */
import axios from "axios";
import CONFIG from "../../config";

const UpdatePiApi = async (piForm: any, PiId: any) => {
  const bearerToken = localStorage.getItem("user") as string;
  const tenantId = "1";
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(bearerToken)}`,
      "X-Tenant-Id": tenantId,
      "Content-Type": "application/json",
    },
  };

  if (!piForm.startDate) {
    piForm.startDate = null;
  }

  if (!piForm.endDate) {
    piForm.endDate = null;
  }

  piForm.schedule *= 7;

  const date1 = new Date(piForm.startDate);
  let date2 = new Date();
  let progress;
  if (piForm.schedule > 0) {
    date2.setDate(date1.getDate() + piForm.schedule);

    const days = Math.round(
      Math.abs((date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24))
    );
    const presentDate = new Date(Date.now()).toISOString();
    const today = new Date(presentDate);
    const presentTime = Math.round(
      Math.abs((date2.getTime() - today.getTime()) / (1000 * 60 * 60 * 24))
    );
    if (date2 === today) {
      progress = 100;
    } else {
      progress = Math.round((Math.abs(days - presentTime) / days) * 100);
    }
  } else {
    date2 = new Date(piForm.endDate);
  }

  const formData = {
    name: piForm.name,
    piScope: piForm.scope,
    piBudget: piForm.budget,
    startAt: date1,
    progress,
    endDate: date2,
    piStatus: piForm.status,
    projectId: piForm.projectId,
  };
  await axios
    .post(
      `${CONFIG[process.env.REACT_APP_MY_ENV].apiEndPoints.phrt}/v1/pi/${PiId}`,
      JSON.stringify(formData),
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export default UpdatePiApi;
