/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-console */
import Loader from "components/Loader";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReportList from "services/api/GetReportApi";
import Pagination, { IPagination } from "components/Pagination";
import CONSTANTS from "common/constants";
import { ProjectReportWrapper } from "./styled";

function ProjectReportTable(props: any) {
  const [DataArr, setDataArr] = useState<any>([]);
  const { setActiveReport } = props;
  const addReportState = () => {
    setActiveReport("AddReport");
  };
  const editReportState = () => {
    setActiveReport("EditReport");
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [paginate, setPagination] = useState<IPagination>({
    pageNumber,
    pageSize: 10,
    totalCount: 20,
    totalPages: 0,
    isFirst: true,
    isLast: true,
    setPageNumber,
    paginationSetLimit: 10,
  });

  const projectId = localStorage.getItem("projectId");
  const preventFlag = localStorage.getItem("preventFlag");
  const hidden = preventFlag === "true" ? true : false;
  let flag = false;

  const dispatch = useDispatch();

  const getReport = async () => {
    await ReportList(projectId, pageNumber)
      .then((data: any) => {
        setDataArr((oldArray: any) => [...oldArray, data.data.reports]);
        setPagination((oldpaginate: IPagination) => ({
          ...oldpaginate,
          ...data._pagination,
        }));
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    setDataArr([]);
    getReport();
  }, [pageNumber]);

  while (DataArr[0] === undefined) {
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    return <Loader />;
  }

  const today = new Date();
  if (DataArr[0][0] !== undefined) {
    const start = new Date(DataArr[0][0].start_date);
    const end = new Date(DataArr[0][0].end_date);

    if (
      today.toISOString().substring(0, 10) >=
        start.toISOString().substring(0, 10) &&
      today.toISOString().substring(0, 10) <= end.toISOString().substring(0, 10)
    ) {
      flag = true;
    }
  }

  dispatch(setPageLoadingStatus({ isPageLoading: false }));

  function setArrows(change: any) {
    switch (change) {
      case CONSTANTS.NOCHANGEGOOD:
        return `${process.env.PUBLIC_URL}/images/ArrowNoChange.svg`;
      case CONSTANTS.GOODCHANGE:
        return `${process.env.PUBLIC_URL}/images/ArrowGoodChange.svg`;
      case CONSTANTS.BADCHANGE:
        return `${process.env.PUBLIC_URL}/images/ArrowBadChange.svg`;
      case CONSTANTS.NOCHANGEBAD:
        return `${process.env.PUBLIC_URL}/images/ArrowNoChangeBad.svg`;
      default:
        return `${process.env.PUBLIC_URL}/images/ArrowNoChange.svg`;
    }
  }
  function checkReportEdit(startDate: any, endDate: any) {
    if (
      today.toISOString().substring(0, 10) >=
        startDate.toISOString().substring(0, 10) &&
      today.toISOString().substring(0, 10) <=
        endDate.toISOString().substring(0, 10)
    )
      return false;
    return true;
  }

  return (
    <ProjectReportWrapper id="maincontent">
      <div
        className={`d-flex flex-row mb-4 align-items-center justify-content-end ${
          hidden ? "hidden-filter-bar" : "filter-bar"
        }`}
      >
        <div>
          <button
            type="button"
            disabled={flag || props.onHoldProp || props.addReport}
            className="btn btn-round btn-primary btn-height"
            data-toggle="tooltip"
            data-placement="bottom"
            title={
              // eslint-disable-next-line no-nested-ternary
              props.addReport
                ? CONSTANTS.POPOVER.endPiMessage
                : props.onHoldProp
                ? CONSTANTS.POPOVER.onHoldMessage
                : CONSTANTS.POPOVER.reportPresent
            }
            onClick={() => {
              if (DataArr[0][0] !== undefined) {
                localStorage.setItem(
                  "reportId",
                  DataArr[0].slice(0)[0].id.toString()
                );
              } else {
                localStorage.setItem("reportId", "null");
              }
              addReportState();
            }}
          >
            Add Report
          </button>
        </div>
      </div>
      <div>
        <table className="aui-responsive-table">
          <thead>
            <tr>
              <th scope="col">Report</th>
              <th scope="col" className="text-lg-center">
                Scope
              </th>
              <th scope="col" className="text-lg-center">
                Quality
              </th>
              <th scope="col" className="text-lg-center">
                Budget
              </th>
              <th scope="col" className="text-lg-center">
                Schedule
              </th>
              <th scope="col" className="text-lg-center">
                Project Health
              </th>
            </tr>
          </thead>
          <tbody hidden={!(DataArr[0].length === 0)}>
            <tr className="aui-status-table-emptystate">
              <td colSpan={6}>
                <div className="aui-td d-flex justify-content-around">
                  No Data
                </div>
              </td>
            </tr>
          </tbody>
          <tbody>
            {DataArr[0].slice(0).map((report: any) => {
              const startDate = new Date(report.start_date);
              const longMonthStart = startDate.toLocaleString("en-us", {
                month: "long",
              });
              const endDate = new Date(report.end_date);
              const longMonthEnd = endDate.toLocaleString("en-us", {
                month: "long",
              });
              return (
                <tr key={report.id}>
                  <td data-title="Report">
                    <div className="aui-td justify-content-between d-flex flex-column text-left">
                      <button
                        type="button"
                        onClick={() => {
                          localStorage.setItem("reportId", report.id);
                          if (checkReportEdit(startDate, endDate)) {
                            props.disableReportEdit(true);
                            console.log(checkReportEdit(startDate, endDate));
                          }
                          editReportState();
                        }}
                        disabled={hidden}
                        className="project-title"
                      >
                        Week {report.week}
                      </button>
                      <div className="duration-div">
                        {longMonthStart} {startDate.getDate()} - {longMonthEnd}{" "}
                        {endDate.getDate()}
                      </div>
                    </div>
                  </td>
                  <td data-title="Scope">
                    <div className="aui-td d-flex justify-content-center">
                      <img
                        className="arrow-img"
                        src={setArrows(report.scope)}
                        alt="Directional Arrow No Change"
                      />
                    </div>
                    <div className="aui-td d-flex justify-content-center description">
                      {report.scope_comment}
                    </div>
                  </td>
                  <td data-title="Quality">
                    <div className="aui-td d-flex justify-content-center">
                      <img
                        className="arrow-img"
                        src={setArrows(report.quality)}
                        alt="Directional Arrow Bad Change"
                      />
                    </div>
                    <div className="aui-td d-flex justify-content-center description">
                      {report.quality_comment}
                    </div>
                  </td>
                  <td data-title="Budget">
                    <div className="aui-td d-flex justify-content-center">
                      <img
                        className="arrow-img"
                        src={setArrows(report.budget)}
                        alt="Directional Arrow Good Change"
                      />
                    </div>
                    <div className="aui-td d-flex justify-content-center description">
                      {report.budget_comment}
                    </div>
                  </td>
                  <td data-title="Schedule">
                    <div className="aui-td d-flex justify-content-center">
                      <img
                        className="arrow-img"
                        src={setArrows(report.schedule)}
                        alt="Directional Arrow Good Change"
                      />
                    </div>
                    <div className="aui-td d-flex justify-content-center description">
                      {report.schedule_comment}
                    </div>
                  </td>
                  <td data-title="Project Health">
                    <div className="aui-td d-flex justify-content-center">
                      <img
                        className="arrow-img"
                        src={setArrows(report.overall_health)}
                        alt="Directional Arrow Good Change"
                      />
                    </div>
                    <div className="aui-td d-flex justify-content-center description">
                      {report.overall_health_comment}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="d-flex justify-content-end">
          <Pagination
            pageNumber={paginate.pageNumber}
            pageSize={paginate.pageSize}
            setPageNumber={paginate.setPageNumber}
            paginationSetLimit={paginate.paginationSetLimit}
            totalCount={paginate.totalCount}
            totalPages={paginate.totalPages}
            isFirst={paginate.isFirst}
            isLast={paginate.isLast}
          />
        </div>
      </div>
    </ProjectReportWrapper>
  );
}

export default ProjectReportTable;
