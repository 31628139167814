/* eslint-disable no-console */
import axios from "axios";
import CONSTANTS from "common/constants";
import AddUserApi from "./AddUserApi";
import CONFIG from "../../config";

const AddProjectApi = async (projectForm: any) => {
  const bearerToken = localStorage.getItem("user") as string;
  const tenantId = "1";
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(bearerToken)}`,
      "X-Tenant-Id": tenantId,
      "Content-Type": "application/json",
    },
  };

  if (!projectForm.startDate) {
    projectForm.startDate = null;
  }

  if (!projectForm.endDate) {
    projectForm.endDate = null;
  }

  const formData = {
    name: projectForm.name,
    description: projectForm.description,
    scope: projectForm.scope,
    budget: projectForm.budget,
    projectManager: projectForm.manager,
    teamSize: projectForm.teamSize,
    startAt: projectForm.startDate,
    endAt: projectForm.endDate,
    status: projectForm.status,
    active: false,
    userId: projectForm.userId,
  };

  if (projectForm.password === "") {
    projectForm.password = CONSTANTS.COMPLETE;
  }

  const userForm = {
    email: projectForm.userId,
    password: projectForm.password,
  };

  await AddUserApi(userForm);
  await axios
    .post(
      `${CONFIG[process.env.REACT_APP_MY_ENV].apiEndPoints.phrt}/v1/projects`,
      JSON.stringify(formData),
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      console.log(error.response.status);
      console.log(error.response.data.error.details[0].message);
      console.log(error.message);
      throw error;
    });
};

export default AddProjectApi;
