import styled from "styled-components";

export const ProjectListWrapper = styled.div`
  min-height: 100vh;
  #maincontent {
    @media (min-width: 992px) {
      height: 100%;
      flex-grow: 1;
    }
    & .content-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      @media (min-width: 992px) {
        flex-direction: row;
      }
      & .left-container {
        padding-inline: 20px;
        @media (min-width: 991px) {
          padding: 19px 10px 19px 21px;
        }
        & div {
          & .heading-bar {
            flex-direction: column;
            padding-bottom: 20px;
            @media (min-width: 576px) {
              flex-direction: row;
            }
            & .font-styled {
              font: normal normal 600 22px/49px Montserrat;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
            }
            & button.btn-height {
              height: auto;
              max-height: 40px;
              padding: 8px 30px 8px 30px;
            }
            & button.btn-width {
              width: 100%;
              @media (min-width: 576px) {
                width: auto;
              }
            }
          }
          & .status-bar {
            flex-direction: column;
            @media (min-width: 576px) {
              flex-direction: row;
            }
            & .flex-prop-media {
              div {
                .Red {
                  color: #eb0f25;
                  font-weight: normal;
                }
                .Green {
                  color: #0d8200;
                  font-weight: normal;
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const AnotherWraper = styled.div``;
