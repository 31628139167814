/* eslint-disable jsx-a11y/label-has-associated-control */
import MyAccounts from "components/MyAccount/MyAccount";
import React from "react";
import TopNavWrapper from "./styled";

export const Header = () => {
  return (
    <TopNavWrapper>
      <header className="flex-basis-prop">
        <MyAccounts />
      </header>
    </TopNavWrapper>
  );
};
export default Header;
