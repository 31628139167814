/* eslint-disable no-console */
import axios from "axios";
import CONFIG from "../../config";

const ReportList = async (projectId: any, pageNumber: any) => {
  const bearerToken = localStorage.getItem("user") as string;
  const tenantId = 1;
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(bearerToken)}`,
      "X-Tenant-Id": tenantId,
    },
  };
  return axios
    .get(
      `${
        CONFIG[process.env.REACT_APP_MY_ENV].apiEndPoints.phrt
      }/v1/reports?projectId=${projectId}&pageNumber=${pageNumber}`,
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      console.log(error.response.status);
      console.log(error.response.data.error);
      console.log(error.response.data.error.details[0].message);
      console.log(error.message);
      throw error;
    });
};

export default ReportList;
