import styled from "styled-components";

const TopNavWrapper = styled.nav`
  display: none;
  @media (min-width: 992px) {
    padding-bottom: 10px;
    margin-bottom: 34px;
    display: block;
  }
  & .flex-basis-prop {
    width: 100%;
    display: flex;
    justify-content: end;
    flex-basis: auto;
    margin-bottom: 16px;
    & .search-container {
      display: flex;
      justify-content: space-between;
      flex-basis: auto;
      width: 100%;
      & form {
        max-width: 400px;
        width: 100%;
        & input {
          width: 100%;
          padding: 5px;
          border: 1px solid #bcc3ca;
          opacity: 1;
          color: black;
        }
      }
    }
  }
`;

export default TopNavWrapper;
