import styled from "styled-components";

export const SignInWrapper = styled.div`
  .aha-certification-flex-logo {
    margin-bottom: 32px;
    @media only screen and (min-width: 992px) {
      margin-bottom: 49px;
    }
  }
  main {
    height: 100vh;
    & .main {
      height: auto;
      @media only screen and (min-width: 992px) {
        height: 100vh;
      }
      & .main-content {
        position: relative;
        width: 100%;
        height: 700px;
        flex-direction: column;
        @media only screen and (min-width: 992px) {
          display: flex !important;
          flex-direction: row;
          height: auto;
        }
        & .image-section {
          width: 100%;
          height: 400px;
          @media only screen and (min-width: 992px) {
            display: block;
            height: auto;
          }
          & .mainpage-image {
            width: 100%;
            height: 100%;
          }
        }
        & .background-login {
          position: absolute;
          margin: auto;
          display: flex;
          @media only screen and (min-width: 992px) {
            position: relative;
            margin: auto;
            display: flex;
          }
          & .login-section {
            background-color: white;
            opacity: 92%;
            margin: 110px 30px 30px 30px;
            padding: 10px 20px 10px 20px;
            border-radius: 10px;
            border: 1px solid #707070;
            @media only screen and (min-width: 992px) {
              padding: 0px 48px 0px 57px;
              margin: 0px;
              border: none;
            }
            & .LoginImage {
              margin-bottom: 10px;
              margin-left: -10px;
              max-width: 388px;
              max-height: 100px;
              width: 100%;
              height: auto;
              margin-top: 18px;
              @media only screen and (min-width: 576px) {
                margin-bottom: 36px;
              }
            }
            & .signin-section {
              justify-content: center;
              flex-direction: column;
              border-bottom: 1px solid #c10e21;
              @media only screen and (min-width: 576px) {
                justify-content: space-between;
                flex-direction: row;
              }
              & .indicate-field {
                margin-top: 0px;
                @media only screen and (min-width: 576px) {
                  font-size: 14px;
                  margin-top: 0px;
                  margin-bottom: 1.25rem;
                  align-self: flex-end;
                }
              }
            }
            & div.signin-section h1.h1 {
              margin-bottom: 10px;
              justify-self: flex-start;
              @media only screen and (min-width: 576px) {
                margin-bottom: 17px;
              }
            }
            & .form-group {
              display: flex;
              align-items: flex-start;
              & .label {
                padding: 27px 0 14px 0;
                font: normal normal normal 17px/24px Montserrat;
              }
              & .input-border {
                border: 1px solid #bcc3ca;
                & .icons {
                  position: absolute;
                  z-index: 300;
                  font-size: 20px;
                }
                & .form-control {
                  max-height: 42px;
                  position: relative;
                  width: 100%;
                  padding-left: 50px;
                }
                & .form-control::placeholder {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
              & .submit-button {
                margin-top: 28px;
                width: 100%;
                align-self: center;
                @media only screen and (min-width: 576px) {
                  align-self: flex-end;
                  display: flex;
                  max-width: 145px;
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const AnotherWraper = styled.div``;
