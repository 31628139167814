/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-debugger, no-console */
import React, { useEffect, useState, Component } from "react";
import Loader from "components/Loader";
import ScrollToTop from "components/scrollToTop";
import Toast from "components/Toast";
import AsideBar from "components/AsideBar";
import Header from "components/Header/index";
import Tables from "components/Table";
import Pagination, { IPagination } from "components/Pagination";
import { Link } from "react-router-dom";
import ProjectList from "services/api/ProjectListApi";
import { useDispatch } from "react-redux";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import CONSTANTS from "common/constants";
import { ProjectListWrapper } from "./styled";
import { Footer } from "../../components/Footer/index";

const Dashboard = () => {
  const [DataArr, setDataArr] = useState<any>([]);
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [paginate, setPagination] = useState<IPagination>({
    pageNumber,
    pageSize: 10,
    totalCount: 20,
    totalPages: 0,
    isFirst: true,
    isLast: true,
    setPageNumber,
    paginationSetLimit: 10,
  });
  const getProjects = async () => {
    await ProjectList(pageNumber)
      .then((data: any) => {
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
        setDataArr((oldArray: any) => [...oldArray, data.data.projects]);
        setPagination((oldpaginate: IPagination) => ({
          ...oldpaginate,
          ...data._pagination,
        }));
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  };

  useEffect(() => {
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    setDataArr([]);
    getProjects();
  }, [pageNumber]);

  return (
    <ProjectListWrapper className="container-fluid d-flex flex-column w-100 p-0">
      <main className="d-flex" id="maincontent">
        <div className="d-flex content-container">
          <AsideBar />
          <div className="left-container flex-grow-1">
            <Header />
            <ScrollToTop />
            <div className="">
              <div className="d-flex justify-content-between heading-bar">
                <h1 className="font-styled">Projects</h1>
                <Link to="/projectlist/addproject">
                  <button
                    type="button"
                    className="btn btn-round btn-primary btn-height btn-width"
                    hidden={
                      localStorage.getItem("role") ===
                        CONSTANTS.ROLES.SUPERADMIN ||
                      localStorage.getItem("role") === CONSTANTS.ROLES.ADMIN
                        ? false
                        : true
                    }
                  >
                    Add Project
                  </button>
                </Link>
              </div>
            </div>
            <Tables DataArr={DataArr} />
            <Pagination
              pageNumber={paginate.pageNumber}
              pageSize={paginate.pageSize}
              setPageNumber={paginate.setPageNumber}
              paginationSetLimit={paginate.paginationSetLimit}
              totalCount={paginate.totalCount}
              totalPages={paginate.totalPages}
              isFirst={paginate.isFirst}
              isLast={paginate.isLast}
            />
          </div>
        </div>
      </main>
      <Footer />
      <Toast />
      <Loader />
    </ProjectListWrapper>
  );
};

export default Dashboard;
