const CONSTANTS = {
  PENDING: "PENDING",
  NOVALUE: "NOVALUE",
  REGISTRED: "APPROVED",
  INTERNATIONAL: "INTERNATIONAL",
  DOMESTIC: "DOMESTIC",
  LOCAL_STORE_KEY: "#%$##%$#",
  ACTIVE: "Active",
  GOODCHANGE: "goodchange",
  BADCHANGE: "badchange",
  NOCHANGEGOOD: "nochangegood",
  NOCHANGEBAD: "nochangebad",
  GREEN: "green",
  SLIDERVAL1: "1W",
  SLIDERVAL2: "17W",
  COMPLETE: "Completed",
  ONHOLD: "On Hold",
  PEND: "Pending",
  START: "Start",
  END: "End",
  PAUSE: "Pause",
  RESUME: "Resume",
  POPOVER: {
    endPiMessage: "currently active PI ended",
    onHoldMessage: "project on hold/copmleted",
    reportPresent: "present week's report already present",
  },
  ROLES: {
    SUPERADMIN: "SUPER ADMIN",
    ADMIN: "ADMIN",
    USER: "USER",
  },
};

export default CONSTANTS;
