import styled from "styled-components";

export const Table = styled.div`
  width: 100%;
  height: auto;
  & .table {
    width: 100%;
    & tbody {
      & tr {
        & td {
          border: 1px solid #e3e3e3;
          text-align: left;
          vertical-align: middle;
          & .elementTitle {
            font: normal normal medium 14px/13px Montserrat;
            color: #43425d;
          }
          & .elementDetails {
            font: normal normal normal 14px/13px Montserrat;
            color: #222328;
            margin-top: 4px;
          }
          & .project-desc {
            font: normal normal normal 14px/13px Montserrat;
            display: block; /* Fallback for non-webkit */
            display: -webkit-box;
            max-width: 150px;
            height: 16px * 1.4 * 1; /* Fallback for non-webkit */
            line-height: 1.4;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          & .message-icon {
            width: 20px;
            font-size: 10px;
          }
        }
        & .title {
          color: #c10e21;
          font-weight: 600;
          text-decoration: underline;
          & .justify-align {
            word-break: keep-all;
          }
        }
        & div {
          .justify-align {
            word-break: keep-all;
          }
        }
      }
    }
  }
`;
export default Table;
