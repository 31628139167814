import styled from "styled-components";

export const EditReportWrapper = styled.div`
  font: normal normal 600 16px/23px Montserrat;
  & .inputElementsDivDisabled {
    width: 80%;
    @media (min-width: 320px) and (max-width: 992px) {
      width: 100%;
    }
  }
  & .weekDate {
    font: normal normal normal 22px/23px Montserrat;
    margin-left: 20px;
  }
  & .weekNumber {
    font: normal normal 600 22px/23px Montserrat;
    color: #c10e21;
  }

  & .headingDiv {
    margin-bottom: 30px;
  }

  & .title {
    display: flex;
    justify-content: space-between;
    padding-top: 28px;
    padding-bottom: 28px;
    color: #222328;
    font-size: 26px;
    border-bottom: 1px solid #bcc3ca;
    margin-bottom: 28px;

    & .editButton {
      background: #c10e21;
      color: white;
      border: none;
      width: 128px;
      height: 40px;
      border-radius: 20px;
      margin-right: 20px;
      border: 1px solid #c10e21;
      font-size: 16px;
    }
  }
  & .week-title-text {
    color: #c10e21;
    text-decoration: underline;
    font: normal normal 600 22px/23px Montserrat;
  }
  & .week-text {
    color: #2b2b2b;
    font: normal normal normal 22px/23px Montserrat;
  }
  & .edtbtn {
    height: 44px;
  }
  & .edit-icon {
    color: #c10e21;
    font-size: 30px;
  }
  & .form {
    & .scopes,
    & .overall-healths {
      background-color: #f1f4f9;
    }
    & .piDropdown {
      margin-top: 20px;
      margin-bottom: 30px;
    }
    & .form-entry {
      padding-right: 16px;
      padding-top: 16px;
      padding-bottom: 16px;
      font-size: 16px;
      @media (min-width: 576px) {
        padding-right: 24px;
        padding-top: 16px;
        padding-bottom: 16px;
      }

      & .forminput {
        position: relative;
        @media (min-width: 768px) {
          position: static;
        }
        & input {
          border: 0.6px solid #bcc3ca;
        }
        & input:disabled {
          border: none;
          background: white;
          cursor: default;
        }
        & .budget,
        & .quality,
        & .schedule,
        & .scope,
        & .overall-health {
          margin-left: 6px;
          min-width: 160px;
          padding-bottom: 16px !important;
          @media (min-width: 768px) {
            min-width: 100px;
            padding-bottom: 0px !important;
          }
        }
        & .overall {
          @media (min-width: 320px) {
            display: inline;
          }
          @media (min-width: 768px) {
            display: none;
          }
          @media (min-width: 1400px) {
            display: inline;
          }
        }
        & .toggle-buttons.d-flex.col-2 {
          max-width: 100% !important;
          min-width: 0% !important;
          @media (min-width: 768px) {
            max-width: 16.66% !important;
            min-width: 16.66% !important;
          }
        }
        & .toggle-buttons {
          position: absolute;

          top: 0;
          right: 0;
          & .toggle-buttons-green,
          & .toggle-buttons-red {
            width: 65px;
            & p {
              padding: 0px;
              margin: 0px;
            }
          }
          & .toggle-buttons-green {
            border: none;
          }
          & .toggle-buttons-red {
            border: 1px solid #d6d6d6;
          }
          & .green {
            background-color: #0d8200;
          }
          & .red {
            background-color: #c10e21;
          }
          @media (min-width: 320px) {
            width: 100px !important;
            height: 30px;
            font-size: 14px !important;
          }

          @media (min-width: 576px) {
            width: 140px !important;
            & .toggle-buttons-green,
            & .toggle-buttons-red {
              font-size: 16px !important;
              height: 35px;
            }
          }

          @media (min-width: 768px) {
            position: static;
            & .toggle-buttons-green,
            & .toggle-buttons-red {
              font-size: 16px !important;
            }
          }
        }
      }
    }

    & textarea {
      height: 100px !important;
      border: 0.6px solid #bcc3ca;
    }
    & textarea:disabled {
      height: 100px !important;
      border: none !important;
      background: white !important;
      cursor: default !important;
    }

    & .overall-health {
      padding-top: 16px !important;
      @media (min-width: 768px) {
        padding-top: 10px !important;
      }
      @media (min-width: 1300px) {
        padding-top: 16px !important;
      }
    }
    & .bck-btn {
      border: none;
      font-weight: 600;
      margin-bottom: 30px;
    }
    & .buttons {
      margin-top: 30px;
      font-size: 16px;
      margin-bottom: 56px;
      & .cancel-button,
      & .submit-button {
        height: 40px;
        width: 100%;
        @media (min-width: 577px) {
          height: 40px;
          width: 128px;
        }
      }
      & .cancel-button {
        margin-right: 0px;
        margin-bottom: 3%;
        @media (min-width: 577px) {
          margin-right: 26px;
          margin-bottom: 0;
        }
      }
    }
  }
  & .lines {
    border-top: 1px solid #bcc3ca;
    margin-bottom: 48px;
  }
`;

export const AddReportFormWrapper = styled.div`
  & .breadcrumb {
    padding: 0px;
    font-size: 16px;
  }
  & .title {
    padding-top: 28px;
    padding-bottom: 28px;
    color: #222328;
    font-size: 26px;
    border-bottom: 1px solid #bcc3ca;
    margin-bottom: 28px;
  }
  & .form {
    & .scopes,
    & .schedules,
    & .overall-healths {
      background-color: #f1f4f9;
    }
    & .piDropdown {
      margin-bottom: 30px;
    }
    & .form-entry {
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 16px;
      padding-bottom: 16px;
      font-size: 16px;
      @media (min-width: 576px) {
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 16px;
        padding-bottom: 16px;
      }

      & .forminput {
        position: relative;
        @media (min-width: 768px) {
          position: static;
        }
        & input {
          border: 0.6px solid #bcc3ca;
        }
        & .budget,
        & .quality,
        & .schedule,
        & .scope,
        & .overall-health {
          min-width: 160px;
          padding-bottom: 16px !important;
          @media (min-width: 768px) {
            min-width: 100px;
            padding-bottom: 0px !important;
          }
        }
        & .overall {
          @media (min-width: 320px) {
            display: inline;
          }
          @media (min-width: 768px) {
            display: none;
          }
          @media (min-width: 1400px) {
            display: inline;
          }
        }
        & .toggle-buttons.d-flex.col-2 {
          max-width: 100% !important;
          min-width: 0% !important;
          @media (min-width: 768px) {
            max-width: 16.66% !important;
            min-width: 16.66% !important;
          }
        }
        & .toggle-buttons {
          position: absolute;

          top: 0;
          right: 0;
          & .toggle-buttons-green,
          & .toggle-buttons-red {
            width: 65px;
            & p {
              padding: 0px;
              margin: 0px;
            }
          }
          & .toggle-buttons-green {
            border: none;
          }
          & .toggle-buttons-red {
            border: 1px solid #d6d6d6;
          }

          & .green {
            background-color: #0d8200;
          }

          & .red {
            background-color: #c10e21;
          }
          @media (min-width: 320px) {
            width: 100px !important;
            height: 30px;
            font-size: 14px !important;
          }

          @media (min-width: 576px) {
            width: 140px !important;
            & .toggle-buttons-green,
            & .toggle-buttons-red {
              font-size: 16px !important;
              height: 35px;
            }
          }

          @media (min-width: 768px) {
            position: static;
            & .toggle-buttons-green,
            & .toggle-buttons-red {
              font-size: 16px !important;
            }
          }
        }
      }
    }

    & .textarea {
      height: 100px !important;
      border: 0.6px solid #bcc3ca;
    }
    & .overall-health {
      padding-top: 16px !important;
      @media (min-width: 768px) {
        padding-top: 10px !important;
      }
      @media (min-width: 1300px) {
        padding-top: 16px !important;
      }
    }
    & .lines {
      margin-top: 20px;
      border-top: 1px solid #bcc3ca;
    }
    & .buttons {
      margin-top: 30px;
      font-size: 16px;
      margin-bottom: 96px;
      & .cancel-button,
      & .submit-button {
        height: 40px;
        width: 100%;
        @media (min-width: 577px) {
          height: 40px;
          width: 128px;
        }
      }
      .cancel-button {
        margin-right: 0px;
        margin-bottom: 3%;
        @media (min-width: 577px) {
          margin-right: 26px;
          margin-bottom: 0;
        }
      }
    }
  }
`;
