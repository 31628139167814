/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-console */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useEffect, useState } from "react";
import Loader from "components/Loader";
import { useForm } from "react-hook-form";
import ScrollToTop from "components/scrollToTop";
import Toast from "components/Toast";
import AsideBar from "components/AsideBar";
import { Header } from "components/Header";
import { Link, useHistory } from "react-router-dom";
import AddProjectApi from "services/api/AddProjectApi";
import { useDispatch } from "react-redux";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { showToast } from "components/Toast/toast.slice";
import UserEmailList from "services/api/UserEmailListApi";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";
import { Footer } from "../../../components/Footer/index";
import AddProjectWrapper from "./styled";

const AddProject = () => {
  const [projectForm, setProjectForm] = useState({
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    status: "Pending",
    manager: "",
    teamSize: 0,
    budget: "",
    userId: "",
    scope: "",
    password: "",
    active: false,
  });
  const [value, setValue] = useState<string | number>(0.0);
  const [currency, setCurrency] = useState("₹");
  const [disablePassword, setDisablePassword] = useState(false);
  const handleOnValueChange: CurrencyInputProps["onValueChange"] = (
    value,
    _,
    values
  ): void => {
    if (!value) {
      setValue("");
      return;
    }
    setValue(value);
    setProjectForm({
      ...projectForm,
      budget: values ? values.formatted : "",
    });
  };
  const [DataArr, setDataArr] = useState<any>([]);
  const [search, setSearch] = useState<any>("");
  const dispatch = useDispatch();
  const { reset, handleSubmit } = useForm();
  const history = useHistory();
  const [startDate, setStartDate] = useState("");
  const routeChange = () => {
    const path = `/projectlist`;
    history.push(path);
  };
  const getUser = async () => {
    await UserEmailList()
      .then((data: any) => {
        setDataArr((oldArray: any) => [...oldArray, data.data.users]);
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      })
      .catch((error: any) => {
        dispatch(showToast({ message: error.message }));
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      });
  };
  useEffect(() => {
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    getUser();
  }, []);
  const onSave = async (e: any) => {
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    await AddProjectApi(projectForm)
      .then((data: any) => {
        routeChange();
        const toast = {
          visibility: true,
          message: "Project Added Successfully",
          title: "Success",
          type: "success",
        };
        dispatch(showToast(toast));
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      })
      .catch((error: any) => {
        console.log(error.response.data);
        if (!(error.response.data.error.details === undefined)) {
          console.log("empty date");
        }
        console.log(
          "signinRedirect error: ",
          error.response.data.error.details[0].message
        );
        const toast = {
          visibility: true,
          message: error.response.data.error.details[0].message,
          title: "Failure",
          type: "danger",
        };
        dispatch(showToast(toast));
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      });
  };
  while (DataArr[0] === undefined) {
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    return <Loader />;
  }
  return (
    <AddProjectWrapper className="container-fluid d-flex flex-column w-100 p-0">
      <div className="d-flex" id="maincontent">
        <div className="d-flex content-container">
          <AsideBar />
          <div className="left-container flex-grow-1">
            <Header />
            <ScrollToTop />
            <main className="">
              <div className="titleDiv">
                <h3>Add Project</h3>
              </div>
              <div className="formDiv">
                <form
                  id="addProjectForm"
                  className="form-group required flex-column"
                  onSubmit={handleSubmit(onSave)}
                >
                  <p className="firstP">Project detail</p>
                  <div className="form-group row required error">
                    <label className="col-sm-3 col-form-label">Name</label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="projectName"
                        aria-label="Enter your Name"
                        onChange={(event) =>
                          setProjectForm({
                            ...projectForm,
                            name: event.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group row required error">
                    <label className="col-sm-3 col-form-label">
                      Project Description
                    </label>
                    <div className="col-sm-8">
                      <textarea
                        className="form-control"
                        id="projectDesc"
                        required
                        aria-label="Enter Project description"
                        onChange={(event) =>
                          setProjectForm({
                            ...projectForm,
                            description: event.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group row required error">
                    <label className="col-sm-3 col-form-label">Scope</label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="scope"
                        required
                        aria-label="Enter scope"
                        onChange={(event) =>
                          setProjectForm({
                            ...projectForm,
                            scope: event.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group row required">
                    <label htmlFor="budget" className="col-sm-3 col-form-label">
                      Budget
                    </label>
                    <div className="col-sm-8">
                      <div className="input-group mb-3 border budget-border">
                        <div className="input-group-prepend">
                          <div className="dropdown p-0 m-0">
                            <button
                              className="btn dropdown-toggle my-0 mx-0 pl-2 pr-1 currency-button shadow-none"
                              type="button"
                              id="dropdownMenu2"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {currency}
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenu2"
                            >
                              <button
                                className="dropdown-item"
                                type="button"
                                value={currency}
                                onClick={() => {
                                  setCurrency("₹");
                                }}
                              >
                                ₹
                              </button>
                              <button
                                className="dropdown-item"
                                type="button"
                                value={currency}
                                onClick={() => {
                                  setCurrency("$");
                                }}
                              >
                                $
                              </button>
                              <button
                                className="dropdown-item"
                                type="button"
                                value={currency}
                                onClick={() => {
                                  setCurrency("€");
                                }}
                              >
                                €
                              </button>
                              <button
                                className="dropdown-item"
                                type="button"
                                value={currency}
                                onClick={() => {
                                  setCurrency("¥");
                                }}
                              >
                                ¥
                              </button>
                            </div>
                          </div>
                        </div>
                        <CurrencyInput
                          id="validationCustom01"
                          name="input-1"
                          className="form-control border-0"
                          value={value}
                          onValueChange={handleOnValueChange}
                          placeholder="Please enter a number"
                          prefix={currency}
                          step={1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group row required error">
                    <label className="col-sm-3 col-form-label">
                      Project Manager
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="projectManager"
                        required
                        aria-label="Enter project manager"
                        onChange={(event) =>
                          setProjectForm({
                            ...projectForm,
                            manager: event.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group row required error">
                    <label className="col-sm-3 col-form-label">Team Size</label>
                    <div className="col-sm-8">
                      <input
                        type="number"
                        className="form-control"
                        id="teamSize"
                        required
                        aria-label="Enter the team size"
                        onChange={(event) =>
                          setProjectForm({
                            ...projectForm,
                            teamSize: Number(event.target.value),
                          })
                        }
                      />
                    </div>
                  </div>
                  <p className="firstP">Schedule</p>
                  <div className="form-group row required error">
                    <label className="col-sm-3 col-form-label">
                      Start Date
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="date"
                        className="form-control"
                        id="startDate"
                        required
                        aria-label="Select Start Date"
                        onChange={(event) => {
                          setProjectForm({
                            ...projectForm,
                            startDate: new Date(
                              event.target.value
                            ).toISOString(),
                          });
                          setStartDate(event.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group row error">
                    <label className="col-sm-3 col-form-label">End Date</label>
                    <div className="col-sm-8">
                      <input
                        type="date"
                        className="form-control"
                        id="endDate"
                        min={startDate}
                        aria-label="Select Start Date"
                        onChange={(event) =>
                          setProjectForm({
                            ...projectForm,
                            endDate:
                              event.target.value === ""
                                ? ""
                                : new Date(event.target.value).toISOString(),
                          })
                        }
                      />
                    </div>
                  </div>
                  <p className="firstP">Login Details</p>
                  <div className="w-100 form-group required error row">
                    <div className="w-100 input-group ">
                      <div className="aui-label col-sm-3">
                        <label className="input-group-text aui-buyfor-txt">
                          User Name<span className="text-danger">*</span>
                        </label>
                      </div>
                      <div
                        id="aui-search-dropdown"
                        className="aui-search-dropdown w-100 col-sm-8 pr-0 pl-3"
                      >
                        <div
                          className={
                            search !== ""
                              ? `aui-input-div position-relative show w-100`
                              : `aui-input-div position-relative w-100`
                          }
                        >
                          <input
                            id="aui-select-active"
                            className="text-truncate form-control search-field"
                            type="text"
                            required
                            placeholder="Enter User Name"
                            aria-label="Enter user email"
                            value={search}
                            onChange={(event) => {
                              setSearch(event.target.value);
                              setDisablePassword(false);
                              setProjectForm({
                                ...projectForm,
                                userId: event.target.value,
                              });
                            }}
                            onClick={() => {
                              setProjectForm({
                                ...projectForm,
                                userId: search,
                              });
                            }}
                          />
                          <button className="btn p-0 m-0 border-0">
                            <i className="aha-icon-arrow-down pr-2 position-absolute aui-downicon"></i>
                          </button>
                        </div>
                        <ul
                          id="aui-select-ul"
                          className={
                            search !== ""
                              ? `aui-search-ui aui-show-ul`
                              : `aui-search-ui`
                          }
                        >
                          {DataArr[0]
                            .slice(0)
                            .filter((user: any) => user.email.includes(search))
                            .map((user: any) => {
                              return (
                                <li key={user.id} value={user.email}>
                                  <span
                                    key={user.id}
                                    className="aui-search-li"
                                    onClick={() => {
                                      setSearch(user.email);
                                      setDisablePassword(true);
                                      setProjectForm({
                                        ...projectForm,
                                        userId: user.email,
                                      });
                                    }}
                                  >
                                    {user.email}
                                  </span>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    id="lastFormDiv"
                    className="form-group row required error"
                  >
                    <label className="col-sm-3 col-form-label">Password</label>
                    <div className="col-sm-8">
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        required
                        disabled={disablePassword}
                        aria-label="Enter password"
                        onChange={(event) =>
                          setProjectForm({
                            ...projectForm,
                            password: event.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <hr className="bottomHr" />
                  <div className="formButtonDiv form-group">
                    <Link to="/projectlist">
                      <button
                        className="button btn btn-secondary btn-round"
                        type="reset"
                        style={{ border: "none" }}
                      >
                        Back
                      </button>
                    </Link>
                    <button
                      className="button btn btn-primary btn-round"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        onSave(e);
                        reset();
                      }}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </main>
          </div>
        </div>
      </div>
      <Footer />
      <Toast />
      <Loader />
    </AddProjectWrapper>
  );
};

export default AddProject;
