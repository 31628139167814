import styled from "styled-components";

const AdminHeaderWrapper = styled.header`
  border-bottom: none !important;
  .aha-certification-logo:after {
    content: "Project Health Report Tool";
    color: black;
  }
  .aha-certification-logo {
    color: transparent;
  }
  .aui-skip-content {
    padding: 16px;
    color: #c10e21;
    position: absolute;
    left: -9999px;
    width: 100%;
    top: 0;
    text-align: center;
    background-color: #fff;
    &:focus {
      left: 0;
      z-index: 500;
    }
  }
  &.aui-pri-header {
    .aui-header-content {
      width: 100%;
    }
    .aui-pri-header-t {
      padding: 0px;
      margin-top: 20px;
      margin-bottom: 20px;
      @media only screen and (min-width: 576px) {
        margin-top: 25px;
      }
    }
  }
`;
export default AdminHeaderWrapper;
