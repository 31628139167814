/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
import CreatePi from "components/PiComponents/CreatePi";
import EditPi from "components/PiComponents/EditPi";
import EditReport from "components/ReportComponents/EditReport";
import PiTable from "components/PiComponents/PiTable";
import ProjectReportTable from "components/ProjectComponents/ProjectReportTable";
import ViewReport from "components/ReportComponents/ViewReport";
import AddReport from "components/ReportComponents/AddReportForm/index";
import React, { useEffect, useState } from "react";
import EditProjectForm from "components/ProjectComponents/EditProjectFrom";
import { NavProjectWrapper } from "./styled";

function NavProject() {
  const [active, setActive] = useState("PiTable");
  const [activeReport, setActiveReport] = useState("ReportTable");
  const [onHoldProp, setOnHoldProp] = useState(false);
  const [reportEdit, disableReportEdit] = useState(false);
  const [hidden, setHidden] = useState<any>(false);
  const [addReport, disableAddReport] = useState<any>(false);
  const [reportsHidden, setReportsHidden] = useState<any>(false);
  const params = new URLSearchParams(window.location.search);
  const reportId = params.get("reportId");
  const ptab = params.get("ptab");

  useEffect(() => {
    if (ptab) {
      setActiveReport("ReportTable");
    }
    if (reportId) {
      setActiveReport("EditReport");
    }
  }, [reportId, ptab]);
  return (
    <NavProjectWrapper id="MyAccount" className="px-2 w-100">
      <h2 className="title">View {localStorage.getItem("projectName")}</h2>
      <div className="navbar-expand-lg">
        <button
          type="button"
          className="aui-navbar-toggler navbar-toggler btn btn-round btn-secondary menu-btn float-right mb-2 py-2 px-4"
          data-toggle="collapse"
          data-target="#navbarList"
          aria-controls="navbarList"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          Menu
        </button>
        <div className="aui-p-tabs">
          <div id="navbarList" className="collapse navbar-collapse">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item" role="presentation">
                <a
                  className={`nav-link ${ptab ? "nil" : "active"}`}
                  data-toggle="tab"
                  href="#ptab1"
                  role="tab"
                >
                  Project
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#ptab2"
                  role="tab"
                  hidden={hidden}
                >
                  Pi List
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className={`nav-link ${ptab ? "active" : "nil"}`}
                  data-toggle="tab"
                  href="#ptab3"
                  role="tab"
                  hidden={hidden || reportsHidden}
                >
                  Project Report
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-content">
            <div
              id="ptab1"
              className={`tab-pane ${ptab ? "fade" : "active"}  pt-4 `}
              role="tabpanel"
            >
              <EditProjectForm
                setHidden={setHidden}
                setOnHoldProp={setOnHoldProp}
              />
            </div>
            <div id="ptab2" className="tab-pane fade pt-4" role="tabpanel">
              {active === "PiTable" && (
                <PiTable
                  setActive={setActive}
                  disableAddReport={disableAddReport}
                  onHoldProp={onHoldProp}
                />
              )}
              {active === "CreatePi" && <CreatePi setActive={setActive} />}
              {active === "EditPi" && (
                <EditPi setActive={setActive} onHoldProp={onHoldProp} />
              )}
            </div>
            <div
              id="ptab3"
              className={`tab-pane ${ptab ? "active" : "fade"} pt-4`}
              role="tabpanel"
            >
              {activeReport === "ReportTable" && (
                <ProjectReportTable
                  setActiveReport={setActiveReport}
                  onHoldProp={onHoldProp}
                  disableReportEdit={disableReportEdit}
                  addReport={addReport}
                />
              )}
              {activeReport === "AddReport" && (
                <AddReport setActiveReport={setActiveReport} />
              )}
              {activeReport === "ViewReport" && (
                <ViewReport
                  setActiveReport={setActiveReport}
                  onHoldProp={onHoldProp}
                />
              )}
              {activeReport === "EditReport" && (
                <EditReport
                  setActiveReport={setActiveReport}
                  onHoldProp={onHoldProp}
                  reportEdit={reportEdit}
                  disableReportEdit={disableReportEdit}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </NavProjectWrapper>
  );
}

export default NavProject;
