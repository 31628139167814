import styled from "styled-components";

const ProjectDetailWrapper = styled.div`
  min-height: 100vh;
  #maincontent {
    @media (min-width: 992px) {
      height: 100%;
      flex-grow: 1;
    }
    & .content-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      @media (min-width: 992px) {
        flex-direction: row;
      }
      & .left-container {
        padding: 0px;
        @media (min-width: 991px) {
          padding: 19px 10px 19px 31px;
        }
        & main {
          @media (min-width: 360px) {
            display: flex;
            flex-direction: column;
            & .headNav {
              display: flex;
              justify-content: space-between;
              margin-bottom: 20px;
              & p {
                font: normal normal normal 26px/13px Montserrat;
              }
              & button {
              background: #c10e21;
              color: white;
              border: none;
              width: 128px;
              height: 40px;
              border-radius: 20px;
              margin-right: 20px;
              border: 1px solid #c10e21;
            }

            & .editableForm{
              & .firstP {
                font-weight: 600;
                border-bottom: 3px solid #c10e21;
                width: fit-content;
                padding-bottom: 5px;
              }
  
              & input {
                border: 0.6000000238418579px solid #bcc3ca;
                color: black;
              }
  
              & input:disabled {
                background-color: white;
              }
  
              & textarea {
                border: 0.6000000238418579px solid #bcc3ca;
              }
            }
          
            & .bottomHr {
              margin-top: 40px;
            }

            & .formButtonDiv {
              display: flex;
              justify-content: flex-end;
              margin-top: 40px;

              & button {
                width: 128px;
                height: 40px;
                border-radius: 20px;
                margin-right: 20px;
                border: 1px solid #c10e21;
              }
            }
          }
          

          & .firstP {
            font-weight: 600;
            border-bottom: 3px solid #c10e21;
            width: fit-content;
            padding-bottom: 5px;
            margin-top: 15px;
          }
          & input {
            border: 0.6000000238418579px solid #bcc3ca;
          }
          & input:disabled {
            background-color: white;
            border: none;
          }
          & textarea {
            border: 0.6000000238418579px solid #bcc3ca;
          }
          & textarea:disabled {
            background-color: white;
            border: none;
          }
          & .formButtonDiv {
            display: flex;
            margin: 10px;
            float: right;

            & button {
              width: 128px;
              height: 40px;
              border-radius: 20px;
              margin-right: 20px;
              border: 1px solid #c10e21;
              @media (max-width: 320px) {
                margin-left: auto;
                margin-right: auto;
                width: 80%;
              }
            }

            & .cancelBtn {
              background: white;
              color: #c10e21;
            }

            & .saveBtn {
              background: #c10e21;
              color: white;
              border: none;
              @media (max-width: 320px) {
                margin-top: 10px;
              }
            }
          }

          & .formButtonDivVisible {
            display: flex;
            margin: 10px;
            float: left;
            & button {
              width: 128px;
              height: 40px;
              margin-right: 20px;
              border: none;
              background: white;
              color: #c10e21;
              font-weight: 600;
              @media (max-width: 320px) {
                margin-left: auto;
                margin-right: auto;
                width: 80%;
              }
            }
        }
      }
    }
  }
`;

export default ProjectDetailWrapper;
