import styled from "styled-components";

export const ProjectManagementWrapper = styled.div`
  min-height: 100vh;
  & body {
    box-sizing: border box;
  }
  #maincontent {
    height: 100%;
    /* margin: 10px; */
    @media (min-width: 576px) {
      /* margin: 0px 21px 18px 0px; */
      flex-grow: 1;
    }
    & .content-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      @media (min-width: 992px) {
        flex-direction: row;
      }
      & .left-container {
        @media (min-width: 992px) {
          padding: 19px 10px 19px 21px;
        }
      }
    }
  }
`;
export const AnotherWraper = styled.div``;
