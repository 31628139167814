/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-debugger, no-console */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import GetProjectById from "services/api/GetProjectById";
import { useDispatch } from "react-redux";
import { showToast } from "components/Toast/toast.slice";
import UpdateProjectsApi from "services/api/UpdateProjectApi";
import CONSTANTS from "common/constants";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";
import ProjectDetailWrapper from "./styled";

const EditProjectForm = (props: any) => {
  const [disabled, setDisabled] = useState(true);
  const [hidden, setHidden] = useState(false);
  const [disableEnd, setDisableEnd] = useState(false);
  const [bottonHidden, setBottomHidden] = useState(true);
  const ProjectId = localStorage.getItem("projectId");
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [confirmEnd, setConfirmEnd] = useState(false);
  const [confirmStart, setConfirmStart] = useState(false);
  const [confirmPause, setConfirmPause] = useState(false);
  const [confirmResume, setConfirmResume] = useState(false);
  const [Data, setData] = useState({
    name: "",
    description: "",
    scope: "",
    teamSize: "",
    startDate: "",
    endDate: "",
    budget: "",
    manager: "",
    status: "",
    userId: "",
    onhold_comment: "",
    password: CONSTANTS.NOCHANGEGOOD,
    active: false,
  });
  const loggedInUser = localStorage.getItem("email");
  const loggedUserRole = localStorage.getItem("role");

  let preventFlag = true;
  if (
    Data.userId === loggedInUser ||
    loggedUserRole === CONSTANTS.ROLES.SUPERADMIN ||
    loggedUserRole === CONSTANTS.ROLES.ADMIN
  ) {
    preventFlag = false;
    localStorage.setItem("preventFlag", "false");
  } else {
    localStorage.setItem("preventFlag", "true");
  }
  const [value, setValue] = useState<any>("");
  const [values, setValues] = useState<any>();
  const [currency, setCurrency] = useState("₹");

  const handleOnValueChange: CurrencyInputProps["onValueChange"] = (
    value,
    _,
    values
  ): void => {
    if (!value) {
      setValue("");
      return;
    }
    setValue(value);
    setValues(values);
  };
  const getProject = async () => {
    await GetProjectById(ProjectId)
      .then((data: any) => {
        setData({
          ...Data,
          name: data.data.Project.name,
          description: data.data.Project.description,
          scope: data.data.Project.scope,
          teamSize: data.data.Project.teamSize,
          startDate: data.data.Project.startAt,
          endDate: data.data.Project.endAt,
          budget: data.data.Project.budget,
          manager: data.data.Project.projectManager,
          active: data.data.Project.active,
          status: data.data.Project.status,
          userId: data.data.Project.userId,
          onhold_comment: data.data.Project.onholdComment,
        });
        setValue(data.data.Project.budget.slice(1).split(",").join(""));
        setCurrency(data.data.Project.budget.slice(0, 1));
        localStorage.setItem("budget", data.data.Project.budget);
        if (data.data.Project.status === CONSTANTS.PEND) {
          props.setHidden(true);
        }
        if (
          data.data.Project.status === CONSTANTS.ONHOLD ||
          data.data.Project.status === CONSTANTS.COMPLETE
        ) {
          props.setOnHoldProp(true);
        }
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    getProject();
  }, []);
  useEffect(() => {
    if (values) {
      setData({
        ...Data,
        budget: `${currency}${value}`,
      });
    }
  }, [values]);
  const onSave = async (e: any) => {
    e.preventDefault();
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    await UpdateProjectsApi(Data, ProjectId)
      .then((data: any) => {
        const toast = {
          visibility: true,
          message: "Project Updated Successfully",
          title: "Success",
          type: "success",
        };
        dispatch(showToast(toast));
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      })
      .catch((error: any) => {
        console.log(error);
        if (!(error.response.data.error.details === undefined)) {
          console.log("empty date");
        }
        console.log(
          "signinRedirect error: ",
          error.response.data.error.details[0].message
        );
        const toast = {
          visibility: true,
          message: error.response.data.error.details[0].message,
          title: "Failure",
          type: "danger",
        };
        dispatch(showToast(toast));
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      });
  };
  function ConfirmEndFunc() {
    return (
      <div className={confirmEnd ? "overlay" : "overlay-hidden"} id="name">
        <div className={confirmEnd ? "background-overlay" : "overlay-hidden"}>
          <label
            htmlFor="ButtonEnd"
            className={
              confirmEnd
                ? "background-overlay flex-direction-row"
                : "overlay-hidden"
            }
          >
            Are you sure you want to end Project : {Data.name}?
          </label>
          <div className="d-flex justify-content-end w-100 confirmDiv">
            <button
              type="button"
              className={
                confirmEnd
                  ? "btn btn-round btn-primary piBtn"
                  : "overlay-hidden"
              }
              id="ButtonEnd"
              onClick={(event) => {
                event.preventDefault();
                setConfirmEnd(false);
                setData({ ...Data, active: false, status: CONSTANTS.COMPLETE });
                onSave(event);
                props.setOnHoldProp(true);
                setDisableEnd(true);
              }}
            >
              Yes
            </button>
            <button
              type="button"
              className={
                confirmEnd
                  ? "btn btn-round btn-secondary piBtn"
                  : "overlay-hidden"
              }
              id="ButtonEnd"
              onClick={(event) => {
                event.preventDefault();
                setConfirmEnd(false);
                setData({ ...Data, active: true, status: CONSTANTS.ACTIVE });
              }}
            >
              No
            </button>
          </div>
        </div>
      </div>
    );
  }

  function ConfirmStartFunc() {
    return (
      <div className={confirmStart ? "overlay" : "overlay-hidden"} id="name">
        <div className={confirmStart ? "background-overlay" : "overlay-hidden"}>
          <label
            htmlFor="ButtonStart"
            className={
              confirmStart
                ? "background-overlay flex-direction-row"
                : "overlay-hidden"
            }
          >
            Are you sure you want to start Project : {Data.name}?
          </label>
          <div className="d-flex justify-content-end w-100 confirmDiv">
            <button
              type="button"
              className={
                confirmStart
                  ? "btn btn-round btn-primary piBtn"
                  : "overlay-hidden"
              }
              id="ButtonStart"
              onClick={(event) => {
                event.preventDefault();
                setConfirmStart(false);
                setData({ ...Data, active: true, status: CONSTANTS.ACTIVE });
                onSave(event);
                props.setHidden(false);
                props.setOnHoldProp(false);
              }}
            >
              Yes
            </button>
            <button
              type="button"
              className={
                confirmStart
                  ? "btn btn-round btn-secondary piBtn"
                  : "overlay-hidden"
              }
              id="ButtonStart"
              onClick={(event) => {
                event.preventDefault();
                setConfirmStart(false);
                setData({ ...Data, active: false, status: CONSTANTS.PEND });
              }}
            >
              No
            </button>
          </div>
        </div>
      </div>
    );
  }

  function ConfirmPauseFunc() {
    return (
      <div className={confirmPause ? "overlay" : "overlay-hidden"} id="name">
        <div className={confirmPause ? "background-overlay" : "overlay-hidden"}>
          <label
            htmlFor="ButtonPause"
            className={
              confirmPause
                ? "background-overlay flex-direction-row"
                : "overlay-hidden"
            }
          >
            Are you sure you want to put the Project on hold : {Data.name}?
          </label>
          <form className="w-100">
            <div className="d-flex justify-content-end w-100 text-area-div">
              <textarea
                required
                placeholder="Reason"
                className="form-control"
                onChange={(event) => {
                  Data.onhold_comment = event.target.value;
                  setData(Data);
                }}
              />
            </div>
            <div className="d-flex justify-content-end w-100 confirmDivPause">
              <button
                type="submit"
                className={
                  confirmPause
                    ? "btn btn-round btn-primary piBtn"
                    : "overlay-hidden"
                }
                id="ButtonPause"
                onClick={(event) => {
                  event.preventDefault();
                  setConfirmPause(false);
                  setData({ ...Data, active: false, status: CONSTANTS.ONHOLD });
                  onSave(event);
                  props.setOnHoldProp(true);
                }}
              >
                Yes
              </button>
              <button
                type="button"
                className={
                  confirmPause
                    ? "btn btn-round btn-secondary piBtn"
                    : "overlay-hidden"
                }
                id="ButtonPause"
                onClick={(event) => {
                  event.preventDefault();
                  setConfirmPause(false);
                  setData({ ...Data, active: true, status: CONSTANTS.ACTIVE });
                }}
              >
                No
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  function ConfirmResumeFunc() {
    return (
      <div className={confirmResume ? "overlay" : "overlay-hidden"} id="name">
        <div
          className={confirmResume ? "background-overlay" : "overlay-hidden"}
        >
          <label
            htmlFor="ButtonStart"
            className={
              confirmResume
                ? "background-overlay flex-direction-row"
                : "overlay-hidden"
            }
          >
            Are you sure you want to resume Project on hold : {Data.name}?
          </label>
          <div className="d-flex justify-content-end w-100 confirmDiv">
            <button
              type="button"
              className={
                confirmResume
                  ? "btn btn-round btn-primary piBtn"
                  : "overlay-hidden"
              }
              id="ButtonStart"
              onClick={(event) => {
                event.preventDefault();
                setConfirmResume(false);
                setData({ ...Data, active: true, status: CONSTANTS.ACTIVE });
                onSave(event);
                props.setOnHoldProp(false);
              }}
            >
              Yes
            </button>
            <button
              type="button"
              className={
                confirmResume
                  ? "btn btn-round btn-secondary piBtn"
                  : "overlay-hidden"
              }
              id="ButtonStart"
              onClick={(event) => {
                event.preventDefault();
                setConfirmResume(false);
                setData({ ...Data, active: false, status: CONSTANTS.ONHOLD });
              }}
            >
              No
            </button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <ProjectDetailWrapper className="container-fluid d-flex flex-column w-100 p-0">
      <ConfirmEndFunc />
      <ConfirmStartFunc />
      <ConfirmPauseFunc />
      <ConfirmResumeFunc />
      <main className="">
        <div className="formDiv">
          <h5
            hidden={
              Data.status === CONSTANTS.ONHOLD && !confirmPause ? false : true
            }
          >
            Project on hold because of : {Data.onhold_comment}
          </h5>
          <form id="editableForm" className="editableForm">
            <div className="form-group d-flex justify-content-between firstFormDiv">
              <p className="firstP">Project detail</p>
              <div
                className={`d-flex buttoneditend ${
                  preventFlag ? "edit-buttons" : ""
                }`}
              >
                <button
                  type="button"
                  className="editPencil btn btn-group-lg"
                  onClick={(e) => {
                    setHidden(!hidden);
                    setDisabled(false);
                    setBottomHidden(!bottonHidden);
                  }}
                  hidden={hidden}
                >
                  <i className="aha-icon-edit edit-icon" />
                </button>
                <div
                  className={
                    hidden || loggedUserRole === CONSTANTS.ROLES.USER
                      ? "overlay-hidden"
                      : "d-flex"
                  }
                >
                  <button
                    type="button"
                    className="edtbtn btn btn-sm btn-round btn-primary pause-button"
                    hidden={
                      Data.status === CONSTANTS.PEND ||
                      Data.status === CONSTANTS.COMPLETE
                        ? true
                        : false
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      if (Data.status === CONSTANTS.ACTIVE) {
                        setData({
                          ...Data,
                          active: false,
                          status: CONSTANTS.ONHOLD,
                        });
                        setConfirmPause(true);
                      } else {
                        setData({
                          ...Data,
                          active: true,
                          status: CONSTANTS.ACTIVE,
                        });
                        setConfirmResume(true);
                      }
                    }}
                    disabled={
                      hidden || loggedUserRole === CONSTANTS.ROLES.USER
                        ? true
                        : false
                    }
                  >
                    {Data.status === CONSTANTS.ACTIVE
                      ? CONSTANTS.PAUSE
                      : CONSTANTS.RESUME}
                  </button>
                  <button
                    type="button"
                    className="edtbtn btn btn-sm btn-round btn-primary"
                    hidden={disableEnd || Data.status === CONSTANTS.COMPLETE}
                    onClick={(e) => {
                      e.preventDefault();
                      if (
                        Data.status === CONSTANTS.PEND ||
                        Data.status === CONSTANTS.COMPLETE
                      ) {
                        setData({
                          ...Data,
                          active: true,
                          status: CONSTANTS.ACTIVE,
                        });
                        setConfirmStart(true);
                      } else {
                        setData({
                          ...Data,
                          active: false,
                          status: CONSTANTS.COMPLETE,
                        });
                        setConfirmEnd(true);
                      }
                    }}
                  >
                    {Data.status === CONSTANTS.PEND ||
                    Data.status === CONSTANTS.COMPLETE
                      ? CONSTANTS.START
                      : CONSTANTS.END}{" "}
                    Project
                  </button>
                </div>
              </div>
            </div>
            <div className="form-group row required error">
              <label className="col-sm-3 col-form-label">Name</label>
              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control"
                  id="projectName"
                  defaultValue={Data.name}
                  aria-label="Enter your Name"
                  required
                  disabled={
                    disabled || loggedUserRole === CONSTANTS.ROLES.USER
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    setData({
                      ...Data,
                      name: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="form-group row required error">
              <label className="col-sm-3 col-form-label">
                Project Description
              </label>
              <div className="col-sm-7">
                <textarea
                  className="form-control"
                  id="projectDesc"
                  defaultValue={Data.description}
                  required
                  aria-label="Enter Project description"
                  disabled={disabled}
                  onChange={(e) => {
                    setData({
                      ...Data,
                      description: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="form-group row required error">
              <label className="col-sm-3 col-form-label">Scope</label>
              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control"
                  id="scope"
                  defaultValue={Data.scope}
                  required
                  aria-label="Enter scope"
                  disabled={disabled}
                  onChange={(e) => {
                    setData({ ...Data, scope: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-group row required error">
              <label className="col-sm-3 col-form-label">Budget</label>
              <div className="col-sm-7">
                <div
                  className={
                    disabled
                      ? "input-group mb-3 border-0"
                      : "input-group mb-3 border budget-border"
                  }
                >
                  <div className={disabled ? "d-none" : "input-group-prepend"}>
                    <div className="dropdown p-0 m-0">
                      <button
                        className={
                          disabled
                            ? "btn dropdown-toggle my-0 mx-0 pl-2 pr-1 currency-hidden shadow-none"
                            : "btn dropdown-toggle my-0 mx-0 pl-2 pr-1 currency-button shadow-none"
                        }
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {currency}
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu2"
                      >
                        <button
                          className="dropdown-item"
                          type="button"
                          value={currency}
                          onClick={() => {
                            setCurrency("₹");
                          }}
                        >
                          ₹
                        </button>
                        <button
                          className="dropdown-item"
                          type="button"
                          value={currency}
                          onClick={() => {
                            setCurrency("$");
                          }}
                        >
                          $
                        </button>
                        <button
                          className="dropdown-item"
                          type="button"
                          value={currency}
                          onClick={() => {
                            setCurrency("€");
                          }}
                        >
                          €
                        </button>
                        <button
                          className="dropdown-item"
                          type="button"
                          value={currency}
                          onClick={() => {
                            setCurrency("¥");
                          }}
                        >
                          ¥
                        </button>
                      </div>
                    </div>
                  </div>
                  <CurrencyInput
                    id="validationCustom01"
                    name="input-1"
                    className="form-control border-0"
                    value={value}
                    onValueChange={handleOnValueChange}
                    prefix={currency}
                    disabled={disabled}
                    step={1}
                  />
                </div>
              </div>
            </div>
            <div className="form-group row required error">
              <label className="col-sm-3 col-form-label">Project Manager</label>
              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control"
                  id="projectManager"
                  defaultValue={Data.manager}
                  required
                  aria-label="Enter project manager"
                  disabled={
                    disabled || loggedUserRole === CONSTANTS.ROLES.USER
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    setData({ ...Data, manager: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-group row required error">
              <label className="col-sm-3 col-form-label">Team Size</label>
              <div className="col-sm-7">
                <input
                  type="number"
                  className="form-control"
                  id="teamSize"
                  defaultValue={Data.teamSize}
                  required
                  aria-label="Enter the team size"
                  disabled={disabled}
                  onChange={(e) => {
                    setData({ ...Data, teamSize: e.target.value });
                  }}
                />
              </div>
            </div>
            <p className="firstP">Schedule</p>
            <div className="form-group row required error">
              <label className="col-sm-3 col-form-label">Start Date</label>
              <div className="col-sm-7">
                <input
                  type={disabled ? "text" : "date"}
                  className="form-control"
                  id="startDate"
                  placeholder={Data.startDate
                    .substring(0, 10)
                    .split("-")
                    .join("-")}
                  defaultValue={Data.startDate
                    .substring(0, 10)
                    .split("-")
                    .join("-")}
                  required
                  aria-label="Select Start Date"
                  disabled={disabled}
                  onChange={(event) => {
                    setData({
                      ...Data,
                      startDate: new Date(event.target.value).toISOString(),
                    });
                    setStartDate(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label">End Date</label>
              <div className="col-sm-7">
                <input
                  type={disabled ? "text" : "date"}
                  className="form-control"
                  id="endDate"
                  placeholder="Enter an end date"
                  defaultValue={
                    Data.endDate === null
                      ? "End Date Not Provided"
                      : Data.endDate.substring(0, 10).split("-").join("-")
                  }
                  aria-label="Select Start Date"
                  min={startDate}
                  disabled={disabled}
                  onChange={(event) =>
                    setData({
                      ...Data,
                      endDate: new Date(event.target.value).toISOString(),
                    })
                  }
                />
              </div>
            </div>
            <p className="firstP">Login Details</p>
            <div className="form-group row required error">
              <label className="col-sm-3 col-form-label">User Email</label>
              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control"
                  id="userId"
                  defaultValue={Data.userId}
                  required
                  aria-label="Enter user email"
                  onChange={(event) =>
                    setData({
                      ...Data,
                      userId: event.target.value,
                    })
                  }
                  disabled={
                    disabled || loggedUserRole === CONSTANTS.ROLES.USER
                      ? true
                      : false
                  }
                />
              </div>
            </div>
            <div id="lastFormDiv" className="form-group row required error">
              <label className="col-sm-3 col-form-label">Password</label>
              <div className="col-sm-7">
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="*********"
                  required
                  aria-label="Enter password"
                  onChange={(event) =>
                    setData({
                      ...Data,
                      password: event.target.value,
                    })
                  }
                  disabled={
                    disabled || loggedUserRole === CONSTANTS.ROLES.USER
                      ? true
                      : false
                  }
                />
              </div>
            </div>
            <hr className="bottomHr" />
            <div className="formButtonDiv" hidden={bottonHidden}>
              <button
                className="btn btn-secondary border-0 btn-round text-nowrap"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setHidden(!hidden);
                  setDisabled(!disabled);
                  setBottomHidden(!bottonHidden);
                }}
              >
                &#60; Back
              </button>
              <button
                className="btn btn-primary btn-round"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setHidden(!hidden);
                  setDisabled(!disabled);
                  setBottomHidden(!bottonHidden);
                  onSave(e);
                }}
              >
                Save
              </button>
            </div>
            <div className="formButtonDivVisible" hidden={!bottonHidden}>
              <Link to="/projectlist">
                <button
                  className="button btn btn-secondary btn-round"
                  type="button"
                >
                  Back
                </button>
              </Link>
            </div>
          </form>
        </div>
      </main>
    </ProjectDetailWrapper>
  );
};

export default EditProjectForm;
