/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-debugger, no-console */
import React, { useEffect, useState } from "react";
import Loader from "components/Loader";
import ScrollToTop from "components/scrollToTop";
import Toast from "components/Toast";
import AsideBar from "components/AsideBar";
import { Header } from "components/Header";
import Table from "components/Table";
import Pagination from "components/Pagination";
import { Link } from "react-router-dom";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import GetProjectById from "services/api/GetProjectById";
import { useDispatch } from "react-redux";
import { showToast } from "components/Toast/toast.slice";
import UpdateProjectsApi from "services/api/UpdateProjectApi";
import ProjectDetailWrapper from "./styled";
import { Footer } from "../../../components/Footer/index";

const ProjectName = () => {
  const [disabled, setDisabled] = useState(true);
  const [hidden, setHidden] = useState(false);
  const [bottonHidden, setBottomHidden] = useState(true);
  const [border, setBorder] = useState(0);
  const ProjectId = localStorage.getItem("projectId");
  const dispatch = useDispatch();
  const [Data, setData] = useState({
    name: "",
    description: "",
    scope: "",
    teamSize: "",
    startDate: "",
    endDate: "",
    budget: "",
    manager: "",
  });

  const getProject = async () => {
    await GetProjectById(ProjectId)
      .then((data: any) => {
        console.log(data.data.Project);
        setData({
          ...Data,
          name: data.data.Project.name,
          description: data.data.Project.description,
          scope: data.data.Project.scope,
          teamSize: data.data.Project.teamSize,
          startDate: data.data.Project.startAt,
          endDate: data.data.Project.endAt,
          budget: data.data.Project.budget,
          manager: data.data.Project.projectManager,
        });
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    getProject();
  }, []);

  const onSave = async (e: any) => {
    e.preventDefault();
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    await UpdateProjectsApi(Data, ProjectId)
      .then((data: any) => {
        debugger;
        const toast = {
          visibility: true,
          message: "Project Updated Successfully",
          title: "Success",
          type: "success",
        };
        dispatch(showToast(toast));
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      })
      .catch((error: any) => {
        debugger;
        console.log(error);
        if (!(error.response.data.error.details === undefined)) {
          console.log("empty date");
        }
        console.log(
          "signinRedirect error: ",
          error.response.data.error.details[0].message
        );
        const toast = {
          visibility: true,
          message: error.response.data.error.details[0].message,
          title: "Failure",
          type: "danger",
        };
        dispatch(showToast(toast));
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      });
  };
  return (
    <ProjectDetailWrapper className="container-fluid d-flex flex-column w-100 p-0">
      <div className="d-flex" id="maincontent">
        <div className="d-flex content-container">
          <AsideBar />
          <div className="left-container flex-grow-1">
            <Header />
            <ScrollToTop />
            <main className="">
              <nav className="headNav">
                <p>View {Data.name}</p>
                <button
                  type="submit"
                  onClick={(e) => {
                    setHidden(!hidden);
                    setDisabled(false);
                    setBottomHidden(!bottonHidden);
                  }}
                  hidden={hidden}
                >
                  Edit Project
                </button>
              </nav>
              <div className="formDiv">
                <form id="editableForm" className="editableForm">
                  <p className="firstP">Project detail</p>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Name</label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="projectName"
                        defaultValue={Data.name}
                        aria-label="Enter your Name"
                        required
                        disabled={disabled}
                        onChange={(e) => {
                          setData({
                            ...Data,
                            name: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Project Description
                    </label>
                    <div className="col-sm-8">
                      <textarea
                        className="form-control"
                        id="projectDesc"
                        defaultValue={Data.description}
                        required
                        aria-label="Enter Project description"
                        disabled={disabled}
                        onChange={(e) => {
                          setData({
                            ...Data,
                            description: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Scope</label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="scope"
                        defaultValue={Data.scope}
                        required
                        aria-label="Enter scope"
                        disabled={disabled}
                        onChange={(e) => {
                          setData({ ...Data, scope: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Budget</label>
                    <div className="col-sm-8">
                      <input
                        type="number"
                        className="form-control"
                        id="budget"
                        defaultValue={Data.budget}
                        required
                        aria-label="Enter budget"
                        disabled={disabled}
                        onChange={(e) => {
                          setData({ ...Data, budget: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Project Manager
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="projectManager"
                        defaultValue={Data.manager}
                        required
                        aria-label="Enter project manager"
                        disabled={disabled}
                        onChange={(e) => {
                          setData({ ...Data, manager: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Team Size</label>
                    <div className="col-sm-8">
                      <input
                        type="number"
                        className="form-control"
                        id="teamSize"
                        defaultValue={Data.teamSize}
                        required
                        aria-label="Enter the team size"
                        disabled={disabled}
                        onChange={(e) => {
                          setData({ ...Data, teamSize: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <p className="firstP">Schedule</p>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Start Date
                    </label>
                    <div className="col-sm-8">
                      <input
                        type={disabled ? "text" : "date"}
                        className="form-control"
                        id="startDate"
                        placeholder={Data.startDate
                          .substring(0, 10)
                          .split("-")
                          /* .reverse() */
                          .join("-")}
                        required
                        aria-label="Select Start Date"
                        disabled={disabled}
                        onChange={(event) =>
                          setData({
                            ...Data,
                            startDate: new Date(
                              event.target.value
                            ).toISOString(),
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">End Date</label>
                    <div className="col-sm-8">
                      <input
                        type={disabled ? "text" : "date"}
                        className="form-control"
                        id="endDate"
                        placeholder={Data.endDate
                          .substring(0, 10)
                          .split("-")
                          /* .reverse() */
                          .join("-")}
                        required
                        aria-label="Select Start Date"
                        disabled={disabled}
                        onChange={(event) =>
                          setData({
                            ...Data,
                            endDate: new Date(event.target.value).toISOString(),
                          })
                        }
                      />
                    </div>
                  </div>
                  <p className="firstP">Login Details</p>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      User Email
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="userId"
                        placeholder="user@user.com"
                        required
                        aria-label="Enter user email"
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div id="lastFormDiv" className="form-group row">
                    <label className="col-sm-3 col-form-label">Password</label>
                    <div className="col-sm-8">
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        required
                        aria-label="Enter password"
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <hr className="bottomHr" />
                  <div className="formButtonDiv" hidden={bottonHidden}>
                    <button
                      className="cancelBtn"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setHidden(!hidden);
                        setDisabled(!disabled);
                        setBottomHidden(!bottonHidden);
                      }}
                    >
                      Go Back
                    </button>
                    <button
                      className="saveBtn"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        setHidden(!hidden);
                        setDisabled(!disabled);
                        setBottomHidden(!bottonHidden);
                        onSave(e);
                      }}
                    >
                      Save
                    </button>
                  </div>
                  <div className="formButtonDivVisible" hidden={!bottonHidden}>
                    <Link to="/projectlist">
                      <button className="cancelBtn" type="button">
                        &#60; Go Back
                      </button>
                    </Link>
                  </div>
                </form>
              </div>
            </main>
            {/* <Pagination pageNumber=1 pageSize=5 totalCount=6 isFirst=true isLast=false /> */}
          </div>
        </div>
      </div>
      <Footer />
      <Toast />
      <Loader />
    </ProjectDetailWrapper>
  );
};

export default ProjectName;
