import styled from "styled-components";

export const DashboardWrapper = styled.div`
  min-height: 100vh;
  body {
    box-sizing: border box;
  }
  main {
    height: 100%;
    flex-grow: 1;
    .content-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      @media (min-width: 320px) and (max-width: 576px) {
        margin-top: -9px;
      }
      @media (min-width: 992px) {
        flex-direction: row;
        margin-left: 0px;
      }
      .left-container {
        padding-inline: 20px;
        @media (min-width: 992px) {
          padding: 19px 10px 19px 21px;
        }
        .T-Title {
          padding-top: 32px;
          .T-Titles {
            font-size: 22px;
          }
          .S-status {
            font-size: 12px;
            text-align: center;
          }

          .Status-Updated {
            margin-left: 28.67px;
          }
          @media (min-width: 320px) {
            flex-direction: column;
            justify-content: none;
            padding-top: 32px;

            .S-Stauses {
              justify-content: flex-start !important;
              font-size: 12px;
            }
          }
          @media (min-width: 992px) {
            flex-direction: row;
            justify-content: space-between;
            padding-top: 32px;

            .S-Stauses {
              justify-content: none;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
`;
export const AnotherWraper = styled.div``;
