import React from "react";
import { ViewReportWrapper } from "./styled";

function EditProject(props: any) {
  const { setActiveReport } = props;
  const ViewProjectReportState = () => {
    setActiveReport("ReportTable");
  };
  return (
    <ViewReportWrapper id="maincontent">
      <div>
        <div className="d-flex flex-row mb-4 p-3 align-items-center justify-content-between filter-bar">
          <button
            type="button"
            className="btn btn-round btn-primary btn-height"
            onClick={ViewProjectReportState}
          >
            Add Report
          </button>
        </div>
      </div>
    </ViewReportWrapper>
  );
}

export default EditProject;
