import styled from "styled-components";

export const ProjectReportWrapper = styled.div`
  font: normal normal 600 16px/23px Montserrat;
  width: 100%;
  height: auto;
  padding-inline: 20px;
  min-height: 100vh;
  #maincontent {
    @media (min-width: 992px) {
      height: 100%;
      flex-grow: 1;
    }
    & .content-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      @media (min-width: 992px) {
        flex-direction: row;
      }
    }
  }
  & .heading-style {
    border-bottom: 1px solid #e3e3e3;
    & .title {
      font: normal normal 600 24px/49px Montserrat;
      color: #000000;
      letter-spacing: 0px;
    }
  }
  & .text-long {
    display: block; /* Fallback for non-webkit */
    display: -webkit-box;
    max-width: 150px;
    height: 16px * 1.4 * 1; /* Fallback for non-webkit */
    margin: 0 auto;
    font-size: 16px;
    line-height: 1.4;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & .arrow-image {
    width: 20px;
  }
  & td {
    & .description {
      font: normal normal normal 14px/13px Montserrat;
      margin-top: 12px;
    }
  }
  & .filter-bar {
    background-color: #e4e4e4;
  }
  & .project-title {
    display: flex;
    color: #c10e21;
    text-decoration: underline;
    background-color: white;
    border: none;
    font: normal normal 600 16px/23px Montserrat;
    padding: 0px;
  }
  & .week-title {
    display: flex;
    color: #c10e21;
    text-decoration: none;
    background-color: white;
    border: none;
    font: normal normal 600 16px/23px Montserrat;
    padding: 0px;
  }
  & .project-content {
    word-break: keep-all;
  }
`;

export const AnotherWraper = styled.div``;
