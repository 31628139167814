/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import React, { useEffect } from "react";
import Loader from "components/Loader";
import { useDispatch } from "react-redux";
import { Table } from "./styled";

function Tables(props: any) {
  const color = "deactivated";
  const dispatch = useDispatch();
  useEffect(() => {
    if (window.AUI.Popover) {
      window.AUI.Popover.init();
    }
  }, []);
  while (props.DataArr[0] === undefined) {
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    return <Loader />;
  }
  dispatch(setPageLoadingStatus({ isPageLoading: false }));
  return (
    <Table>
      <table className="aui-responsive-status-table table">
        <thead className="visuallyhidden">
          <tr>
            <th scope="col">Project Title</th>
            <th scope="col">Project Manager</th>
            <th scope="col">Description</th>
            <th scope="col">Team Size</th>
            <th scope="col">Start Date</th>
            <th scope="col">Project Status</th>
          </tr>
        </thead>
        <tbody hidden={!(props.DataArr[0].length === 0)}>
          <tr className="aui-status-table-emptystate">
            <td colSpan={6}>
              <div className="aui-td d-flex justify-content-around">
                No Data
              </div>
            </td>
          </tr>
        </tbody>
        <tbody>
          {props.DataArr[0].slice(0).map((projects: any) => {
            return (
              <tr
                key={`Project-${projects.id}`}
                className={`aui-table-status-${
                  projects.status === "Pending"
                    ? "pending"
                    : projects.status === "Active"
                    ? "success"
                    : color
                }`}
              >
                <td className="title" data-title="Project Title">
                  <div className="aui-td justify-align">
                    <a
                      href="/projectlist/projectmanagement"
                      onClick={(e) => {
                        localStorage.setItem("projectName", projects.name);
                        localStorage.setItem("projectId", projects.id);
                      }}
                    >
                      {projects.name}
                    </a>
                  </div>
                </td>
                <td data-title="Project Manager">
                  <div className="aui-td flex-column align-items-start justify-content-center">
                    <div className="justify-align sr-only-focusable">
                      <span className="elementTitle d-sm-none d-md-block">
                        Project Manager&#10240;
                      </span>
                    </div>
                    <span className="elementDetails">
                      {projects.projectManager}
                    </span>
                  </div>
                </td>
                <td data-title="Description">
                  <div className="aui-td flex-column align-items-start justify-content-center">
                    <div className="d-flex flex-column justify-align w-100 mt-2">
                      <div className="d-flex flex-row flex-nowrap">
                        <span className="elementTitle d-sm-none d-md-block">
                          Description&#10240;
                        </span>
                      </div>
                      <div className="d-flex justify-content-between">
                        <span className="elementDetails project-desc flex-grow-1">
                          {projects.description}
                        </span>
                        <div className="mt-1">
                          <button
                            type="button"
                            className="btn p-0 m-0"
                            data-toggle="popover"
                            data-placement="top"
                            data-html="true"
                            data-trigger="hover click"
                            data-content={`<div class="text-center aui-delete-popover">
                            <p>${projects.description}</p>
                            </div>`}
                          >
                            <i className="aha-icon-email message-icon" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td data-title="Team Size">
                  <div className="aui-td flex-column justify-content-center">
                    <div className="justify-align">
                      <span className="elementTitle d-sm-none d-md-block">
                        Team Size&#10240;
                      </span>
                    </div>
                    <span className="elementDetails">
                      {projects.teamSize}&#10240;
                    </span>
                  </div>
                </td>
                <td data-title="Start Date">
                  <div className="aui-td flex-column align-items-center justify-content-center">
                    <div className="justify-align">
                      <span className="elementTitle d-sm-none d-md-block">
                        Start Date&#10240;
                      </span>
                    </div>
                    <span className="elementDetails">
                      {projects.startAt
                        .substring(0, 10)
                        .split("-")
                        .reverse()
                        .join("-")}
                    </span>
                  </div>
                </td>
                <td data-title="Pi Status">
                  <div className="aui-td flex-column align-items-center justify-content-center">
                    <div className="d-flex flex-row justify-content-between">
                      <div className="d-flex flex-column">
                        <span className="elementTitle">{projects.status}</span>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Table>
  );
}

export default Tables;
