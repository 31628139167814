/* eslint-disable no-debugger, no-console */
// eslint-disable-next-line no-use-before-define
import React from "react";
import Loader from "components/Loader";
import ScrollToTop from "components/scrollToTop";
import Toast from "components/Toast";
import AsideBar from "components/AsideBar";
import TopNavBar from "components/Header";
import AddReportForm from "components/ReportComponents/AddReportForm/index";
import Footer from "components/Footer/index";
import { AddReportWrapper } from "./styled";

const AddReport = () => {
  return (
    <AddReportWrapper className="container-fluid d-flex flex-column w-100 p-0">
      <main className="d-flex" id="maincontent">
        <div className="content-container">
          <AsideBar />
          <div className="left-container flex-grow-1">
            <TopNavBar />
            <ScrollToTop />
            <AddReportForm />
          </div>
        </div>
      </main>
      <Footer />
      <Toast />
      <Loader />
    </AddReportWrapper>
  );
};

export default AddReport;
