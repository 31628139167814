import styled from "styled-components";

export const AddReportWrapper = styled.div`
  body {
    box-sizing: border box;
  }
  #maincontent {
    height: 100%;
    margin: 0px;
    @media (min-width: 576px) {
      margin: 0px;
      flex-grow: 1;
      padding-left: 0px;
    }
    & .content-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      @media (min-width: 992px) {
        flex-direction: row;
        padding-left: 0px;
      }
      & .left-container {
        @media (min-width: 992px) {
          padding: 19px 10px 19px 31px;
        }
      }
    }
  }
`;
export const AnotherWraper = styled.div``;
