/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
import React, { useEffect } from "react";
import history from "common/history";
import { MyAccount } from "./styled";

function MyAccounts() {
    const Signout = () => {
        localStorage.clear()
        history.push("/")
    }
    return (
        <MyAccount className="navbar aui-profile d-inline-block " id="MyAccount">
            <button
                aria-label="user-profile"
                className="btn btn-text aui-dropdown aha-icon-arrow-down dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                type="button"
            >
                <i className="pr-4" />
                My Account
            </button>
            <ul className="aui-topright-arrow dropdown-menu p-lg-0 header-dropdown-menu">
                <li className="account-list py-2">
                    <a className="dropdown-item" href="/" onClick={Signout}>
                        Sign Out
                    </a>
                </li>
            </ul>
        </MyAccount>
    );
}
export default MyAccounts;
