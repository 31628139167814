/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-debugger, no-console */
import React, * as react from "react";
import Loader from "components/Loader";
import Toast from "components/Toast";
import ScrollToTop from "components/scrollToTop";
import { Wrapper } from "styled";
import Login from "services/api/LoginApi";
import history from "common/history";
import { showToast } from "components/Toast/toast.slice";
import { useDispatch } from "react-redux";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { Footer } from "../../components/Footer/index";
import { SignInWrapper } from "./styled";

const HomePage = () => {
  const [email, setEmail] = react.useState("email");
  const [password, setPassword] = react.useState("password");
  const dispatch = useDispatch();

  const handleLogin = (e: any) => {
    e.preventDefault();
    Login(email, password)
      .then((data: any) => {
        history.push("/dashboard");
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        window.location.reload();
      })
      .catch((error: any) => {
        console.log(error);
        if (!(error.response.data.error.details === undefined)) {
          console.log(
            "signinRedirect error: ",
            error.response.data.error.details[0].message
          );
          const toast = {
            visibility: true,
            message: error.response.data.error.details[0].message,
            title: "Failure",
            type: "danger",
          };
          dispatch(showToast(toast));
          dispatch(setPageLoadingStatus({ isPageLoading: false }));
        } else {
          const toast = {
            visibility: true,
            message: error.response.data.error,
            title: "Failure",
            type: "danger",
          };
          dispatch(showToast(toast));
          dispatch(setPageLoadingStatus({ isPageLoading: false }));
        }
      });
  };
  return (
    <Wrapper>
      <SignInWrapper>
        <main>
          <ScrollToTop />
          <Toast />
          <div className="d-flex flex-column main">
            <div className="main-content d-flex flex-grow-1">
              <div className="w-100 image-section" id="maincontent">
                <img
                  className="mainpage-image"
                  src={`${process.env.PUBLIC_URL}/images/homepageMeeting.jpg`}
                  alt="Homepage"
                />
              </div>
              <div className="w-100 background-login">
                <div className="d-flex flex-column login-section flex-grow-1">
                  <img
                    className="LoginImage"
                    src={`${process.env.PUBLIC_URL}/images/PHRTLogin.png`}
                    alt="American Heart Association's Project Health Report Tool Logo"
                  />
                  <div className="d-flex signin-section">
                    <h1
                      className="h1"
                      style={{ color: "#555555", fontSize: "30px" }}
                    >
                      Sign In
                    </h1>
                    <p className="indicate-field">
                      <strong style={{ color: "#FF0000" }}>*</strong> Indicates
                      a required field
                    </p>
                  </div>
                  <form
                    className="form-group required flex-column"
                    action=""
                    method="post"
                  >
                    <label
                      className="col-sm-2 col-form-label label"
                      htmlFor="inputtext1"
                    >
                      Username/Email
                    </label>
                    <div className="input-border d-flex flex-row w-100">
                      <i className="mt-1 px-3 icons aha-icon-profile-android" />
                      <input
                        type="email"
                        className="form-control border-0 d-flex flex-grow-1"
                        placeholder="admin@heart.org"
                        id="inputtext1"
                        onChange={(event) => setEmail(event.target.value)}
                        required
                      />
                    </div>
                    <label
                      className="col-sm-2 col-form-label label"
                      htmlFor="inputtext2"
                    >
                      Password
                    </label>
                    <div className="input-border d-flex flex-row w-100">
                      <div className="mt-1 px-3 icons">&#128274;</div>
                      <input
                        type="password"
                        className="form-control border-0 d-flex flex-grow-1"
                        placeholder="&#8727;&#8727;&#8727;&#8727;&#8727;&#8727;&#8727;&#8727;"
                        id="inputtext2"
                        onChange={(event) => setPassword(event.target.value)}
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-round btn-primary submit-button"
                      onClick={(e) => handleLogin(e)}
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <Footer />
          </div>
          <Loader />
        </main>
      </SignInWrapper>
    </Wrapper>
  );
};

export default HomePage;
