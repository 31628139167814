/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
// eslint-disable-next-line no-use-before-define
import Loader from "components/Loader";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import GetProjectCount from "services/api/GetProjectCount";
import CardsWrapper from "./styled";

export const DashBoardCards = (props: any) => {
  const [DataArr, setDataArr] = useState<any>({
    projectCount: 0,
    activeProjectCount: 0,
    pendingProjectCount: 0,
  });
  const dispatch = useDispatch();
  const getProjectsCount = async () => {
    await GetProjectCount()
      .then((data: any) => {
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
        setDataArr((oldArray: any) => ({
          ...oldArray,
          ...data.data.ProjectCount,
        }));
      })
      .catch((error) => {
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
        console.log(error.response.data.error);
      });
  };
  useEffect(() => {
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    getProjectsCount();
  }, []);
  return (
    <CardsWrapper>
      <div
        className="first-container d-flex flex-row justify-content-between flex-wrap"
        aria-label="Overview of Projects"
      >
        <div className="col-md-4 p-0">
          <div className="cardboard_a d-flex flex-row">
            <img src="images/graph@2x.png" alt="graph" className="image-a" />

            <div className="text_a d-flex flex-column justify-content-between ">
              <p>Total Projects</p>
              <p className="values">{DataArr.projectCount}</p>
            </div>
          </div>
        </div>
        <div className="col-md-4 p-0">
          <div className="cardboard_b d-flex flex-row">
            <img src="images/increase@2x.png" alt="graph" className="image-b" />

            <div className="text_b d-flex flex-column justify-content-between ">
              <p>Active Projects</p>
              <p className="values">{DataArr.activeProjectCount}</p>
            </div>
          </div>
        </div>
        <div className="col-md-4 p-0">
          <div className="cardboard_c d-flex flex-row">
            <img src="images/process@2x.png" alt="graph" className="image-c" />

            <div className="text_c d-flex flex-column justify-content-between">
              <p>Upcoming Projects</p>
              <p className="values">{DataArr.pendingProjectCount}</p>
            </div>
          </div>
        </div>
      </div>
    </CardsWrapper>
  );
};
export default DashBoardCards;
