import React from "react";
import { Switch } from "react-router-dom";
import Dashboard from "pages/Dashboard/DashboardPage";
import ProjectList from "pages/Projects/ProjectPage";
import ProjectName from "pages/Projects/ProjectDetail/ProjectDetailPage";
import AddProject from "pages/Projects/AddProject/AddProjectPage";
import AddReport from "pages/Reports/AddReport/Addreport";
import ReportList from "pages/Reports/ReportListPage/ReportListPage";
import ProjectManagement from "pages/Projects/ProjectManagement/ProjectManagementPage";
import HomePage from "pages/Loginpage/Loginpage";
import Route from "./Route";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={HomePage as any} hideHeader />
      <Route
        path="/dashboard"
        isPrivate
        component={Dashboard as any}
        hideHeader
      />
      <Route
        exact
        path="/projectlist"
        component={ProjectList as any}
        hideHeader
      />
      <Route
        path="/projectlist/projectname"
        component={ProjectName as any}
        hideHeader
      />
      <Route
        exact
        path="/projectlist/projectmanagement"
        component={ProjectManagement as any}
        hideHeader
      />
      <Route
        path="/projectlist/addproject"
        component={AddProject as any}
        hideHeader
      />
      <Route
        path="/projectlist/addreport"
        component={AddReport as any}
        hideHeader
      />
      <Route path="/reportslist" component={ReportList as any} hideHeader />
    </Switch>
  );
}
