import styled from "styled-components";

export const NavProjectWrapper = styled.div`
  font: normal normal normal 16px Montserrat;
  & .title {
    font: normal normal normal 24px Montserrat;
    color: #222328;
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 20px;
    padding-left: 10px;
  }
  & .navbar-expand-lg {
    padding-top: 10px;
  }
  & .aui-p-tabs {
    margin-top: 10px;
  }
  & .menu-button {
    width: 300px !important;
  }
`;
export default NavProjectWrapper;
